<template>
  <div id="agreement" class="t">
    <pdf
      v-for="currentPage in pageCount"
      :key="currentPage"
      :src="src"
      :page="currentPage"
    >
    </pdf>

    <!-- <iframe
    src="http://view.officeapps.live.com/op/view.aspx?src=/Agreement/PrivacyPolicy.pdf"
    frameborder="0"
    width="100%"
    scrolling="no"
  ></iframe> -->
    <!-- 服务条款 -->
    <!-- <div class="content" v-if="$route.params.name === 'TermsOfService'">
      <p><b>正品汇服务条款</b></p>
      <p><b>版本更新日期：2022年6月1日</b></p>
      <p><b>版本生效日期：2022年6月30日</b></p>
      <p>本协议签订地：深圳市福田区</p>
      <br />
      <p>
        <b
          >重要须知：正品汇网站（www.zph.realbrand.com）、客户端、小程序等产品和服务（以下统称“正品汇平台”或“本平台”）所有权人及其关联方</b
        >（下称“正品汇”）<b>在此特别提醒您仔细阅读</b>（未成年人应在其法定监护人的陪同下阅读）<b>本《正品汇服务条款》</b>（下称“本协议”）
        <b>中的各个条款，尤其是以粗体并下划线标示的条款，</b
        ><span
          >包括但不限于免除或者限制正品汇责任的条款、对用户权利进行限制的条款以及约定争议解决方式、司法管辖的条款。您有权选择同意或者不同意本协议。</span
        >
      </p>
      <p>
        <span
          >您与正品汇均应当严格履行本协议及其补充协议所约定的各项义务，如发生争议或者纠纷，双方可以友好协商解决；协商不成的，任何一方均可向本协议签订地有管辖权的人民法院提起诉讼。本协议签订地为深圳市福田区。</span
        >
      </p>
      <p>
        <b
          >您如果通过登录正品汇平台用户注册页面或者正品汇提供的其他用户注册渠道注册用户账号，完成我们的注册流程并通过点击同意的形式在线签署以下协议即视为您完全同意本协议，愿意接受本协议所有及任何条款的约束。</b
        >
      </p>
      <p><b>1.名词解释</b></p>
      <p>
        除您与正品汇另有约定外，本协议及其补充协议当中的下列名词均采用如下解释：
      </p>
      <p>
        <b>1.1 正品汇服务条款：</b
        >即本协议，指您与正品汇当下订立的旨在约定您登录、使用本平台，以及通过本平台下达订单、购买商品、支付货款、收取商品等整个网购过程中，您与正品汇之间的权利、义务的书面合同。
      </p>
      <p>
        <b>1.2 正品汇平台：</b
        >指正品汇享有全部知识产权、提供给您及其他用户登录和使用的正品汇网站及APP客户端。
      </p>
      <p>
        <b>1.3 商品：</b>是销售商通过本平台向您推荐并进行销售的具体商品的统称。
        1.4
        销售商：通过本平台销售商品和提供服务的公司，根据销售主体的不同，区分为自营销售商及第三方销售商，自营销售商为正品汇公司，第三方销售商为入驻本平台的除正品汇外的第三方商家。
      </p>
      <p>
        <b>1.5 购物车：</b
        >是模拟线下实体商店提供给顾客放置商品的“购物车”的一个概念，指本平台向您展示您已经下达订单、准备购买但尚未支付相应的购买价款的商品信息的网页区域，又称“虚拟购物车”。
      </p>
      <p>
        <b>1.6 订单：</b
        >指由本平台结帐程序生成的记录您通过本平台所购买的商品的名称、品牌、价款、折扣等交易信息的表格。这份文件将被用作所有可能发生的与您购买有关的询问、请求和争议的参考。
      </p>
      <p>
        <b>1.7 送货单：</b
        >指销售商附录在您所订购的商品的包裹上的、供会员签收的商品销售凭证，送货单一般会简要地说明了对应包裹的每一件商品的名称和数量。
      </p>
      <p>
        <b>1.8 送货地址：</b
        >指您或者其他用户通过本平台购买商品所要求的送货地址，如果没有特别说明，即为您填写的订单上所记载的送货地址。
      </p>
      <p>
        <b>1.9 用户账号：</b
        >指您通过登录本平台或者正品汇提供其他的用户账号注册渠道，注册获得并可据以登录本平台的账号。
      </p>
      <p>
        <b>1.10 恶意评论：</b
        >是指个人或组织对本平台、正品汇和/或销售商对外销售的商品没有兴趣，却组织人力或者采用计算机程序模拟正常用户在本平台上大规模发表用户评论，特别发表对本平台、正品汇和/或销售商对外销售的商品负面的评论乃至违反国家有关法律法规的评论。
      </p>
      <p>
        <b>1.11 正品汇平台规则：</b
        >本平台在论坛、帮助中心内已经发布及后续发布的各类规则、实施细则、解读、产品流程说明、公告等。
      </p>
      <p>2.合同目的</p>
      <p>
        本协议旨在约定您在登录、使用本平台，通过本平台购买商品的过程中，您与正品汇双方分别作为本协议的一方合同当事人所享有的权利和所负有的义务。
      </p>
      <p>3.用户账号</p>
      <p>
        3.1
        您确认，在您开始注册程序使用正品汇平台服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。<span
          >若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而造成的一切后果。</span
        >
      </p>
      <p>
        3.2
        您可以通过登录本平台的用户账号注册页面注册用户账号并设置相应的登录密码，一经注册成功，您即可以凭借用户账号及登录密码登录及使用本平台。就目前而言，上述用户账号既可能是您的移动电话号码，也可能是您的个人邮箱账号。
      </p>
      <p>
        3.3
        您成功注册用户账号后，如果需要使用该用户账号从本平台购买商品的，至少还应当填写一个收货人、收货地址和手机号码。除此之外，您还可以按照本平台相关页面的说明填写该用户账号项下的用户姓名、性别、生日、移动电话、固定电话、所在地地址、个人邮箱、婚姻状况、体重以及身高等个人信息，上述个人信息仅作为完善您的用户账号资料之用，是否填写并不影响您从本平台购买商品。
      </p>
      <p>
        3.4
        <b
          >正品汇平台只允许每位用户使用一个正品汇平台账户。如有证据证明或正品汇根据正品汇平台规则判断您存在不当注册或不当使用多个正品汇平台账户的情形，正品汇平台可以采取冻结或关闭账户、取消订单、拒绝提供服务等措施，如给正品汇平台及相关方造成损失的，您还应承担赔偿责任。</b
        >
      </p>
      <p>
        3.5
        您务必妥善保管您的用户账号、登录密码、支付密码（如有）及您在其项下填写的所有个人信息。<span>账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，本平台并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。</span>除本平台存在过错外，<span
          >您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）负责。</span
        >
      </p>
      <p>
        3.6
        为了防止您及其他用户的用户账号及其项下的个人信息泄露或者被他人窃取，正品汇可能会随时采取计算机病毒查杀技术、计算机加密技术等措施进行保护。对此，您完全同意并接受，但这并不能免除本协议第3.5条所述的、您对用户账号及其项下的个人信息所负有的妥善保管义务。
      </p>
      <p>
        3.7
        由于正品汇平台账户关联您的个人信息及平台商业信息，您的正品汇平台账户仅限您本人使用。仅当有法律明文规定、司法裁定或经本平台同意，您方能进行账户的转让。您的账户一经转让，该账户项下的权利义务一并转移。除此外，您的账户不得以任何方式转让，否则本平台有权追究您的违约责任，由此产生的一切责任由您承担。
      </p>
      <p>
        3.8
        您如果遗忘了密码或者密码被他人修改，您可以按照正品汇公布的账号及密码找回规则，通过正品汇提供的账号及密码找回系统程序进行找回，或者在第一时间联系正品汇的客服人员协助找回，但正品汇不保证一定可以找回。
      </p>
      <p>
        <span
          >3.9
          您充分理解并完全同意：根据您的用户账号下的商品购买记录及其他相关信息，销售商如果发现或者合理怀疑您此前或者当次通过本平台或者正品汇提供的其他网购渠道购买的商品有出现并非用于个人消费或者使用用途等在《违规订单处理规则》<a
            href=""
            >（ ）</a
          >
          中列明情形的，对于您的这个用户账号的商品购买需求，销售商有权依据《违规订单处理规则》规定采取不予接受或取消交易并不予发货等措施，具体以《违规订单处理规则》公示内容为准。而且，正品汇视情况还有权冻结您的这个用户账号，使之无法通过本平台以及正品汇提供的其他网购渠道下达订单、购买商品。</span
        >
      </p>
      <p>
        3.10
        <b
          >作为一个正品汇的会员，您可以邀请任何一个您的家庭成员、朋友、同事加入本站。这样您成为他们的邀请人。然而，假如您希望扩大邀请至您家庭成员、朋友、同事等直系圈子以外的人，或者您希望把您的网站链接到本平台，您必须获取正品汇的预先可。在未取得正品汇事先的书面许可情况下，任何会员通过任何方式组织任何类型的大规模邀请计划，导致不符合前述条款的，将被禁止使用本平台，并且他的个人帐户将被取消。为了防止任何发动直系圈子以外的人自己成为会员的企图，最多两个不同的会员可以使用同一个住所地址。</b
        >
      </p>
      <p>
        <span
          >3.11 本平台为您提供账户注销功能，您可以按照<a
            href="/Agreement/PrivacyPolicy.pdf"
            >《正品汇隐私政策》</a
          >的指引注销您的个人账户。</span
        >
      </p>
      <p>
        <span
          >我们在此善意的提醒您，您注销账户的行为会给您的售后维权带来诸多不便，且注销账户后，您的个人信息我们只会在本平台前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。您知晓，根据法律法规规定，相关交易记录可能须在平台后台保存5年甚至更长时间。</span
        >
      </p>
      <p><b>4.商品描述与营销信息</b></p>
      <p>
        <span
          >4.1
          商品描述：除非销售商或者本平台另有说明，销售商出售的商品均为正宗的商品。正品汇直接与销售商合作开展各项营销活动，相关商品信息均由销售商提供，正品汇尽可能根据所接收到的商品信息准确、详尽地描述每一件商品。然而，由于销售商所提供的商品不一定是准确、完整、可靠、有效和没有错误的，因此正品汇不能保证本平台所有商品的描述和其他相关内容是准确、完整、可靠、有效和没有错误的。</span
        >
      </p>
      <p>
        <span
          >4.2
          营销信息：您一经注册用户账号，即视为您同意正品汇及/或其关联公司通过短信或者电子邮件、语音外呼等方式向您注册时填写的手机号码或者电子邮箱发送相应的商品广告信息、促销优惠等营销信息；您如果不同意发送，您可以通过相应的退订功能进行退订。</span
        >
      </p>
      <p><b>5.商品价格</b></p>
      <p>
        本平台上显示的所有价格都是以人民币为计价单位，包括所有的税费，不包括运费。结帐之前运费会自动计算包含在订单总价之内。在商品详情页面里您可以找到所有的相关信息，支付价格为下订单时的有效价格。
      </p>
      <p><b>6.订单</b></p>
      <p>
        6.1
        正品汇保留对单个商品的总出售数量进行限制、对单个订单的商品购买数量及同一IP地址对同类商品购买数量进行限制的权利。
      </p>
      <p>
        6.2
        根据您填写的订单信息，系统将会生成一份包含您的订单的所有必要信息的电子订单表格。在结帐的过程中，您将被邀请复查订购商品的信息、付款方式以及这些商
        品的运输方式和费用。依据这些信息生成的最终订单表格被认为是该次交易的有效证据，这份文件被认为是所有的发货、问题、退货和争议事项的参考，所以您必须
        非常仔细地复查这些信息并纠正一切可能的错误。点击“完成”意味着您认可订单表格中包含的所有信息都是正确和完整的。
      </p>
      <p>
        6.3
        如果您填写的收货人与用户本人不一致的，收货人的行为和意思表示视为您的行为和意思表示，您应对收货人的行为及意思表示的法律后果承担连带责任。
      </p>
      <p><b>7.取消订单</b></p>
      <p>
        7.1
        如您需修改订单请在下单后一小时内自助网上修改（包括合并订单），如订单显示“订单已审核”将不能再进行修改。
      </p>
      <p>
        7.2
        您也可以选择取消订单，对反复的取消行为，我们可能会调查其原因，也可能会成为我们可能拒绝向您提供服务、冻结或关闭您的个人帐户的原因。
      </p>
      <p><b>8.合同成立</b></p>
      <p>
        <span
          >当您作为消费者为生活消费需要下单并支付货款的情况下，您货款支付成功后即视为您与销售商之间就已支付货款部分的订单成立合同关系。</span
        >
      </p>
      <p><b>9.商品缺货</b></p>
      <p>
        <b
          >尽管销售商做出最大的努力，但由于市场变化及各种以合理商业努力难以控制因素的影响，无法避免的会出现您提交的订单信息中的商品出现缺货、价格标示错误等情况；如您下单所购买的商品出现以上情况，造成了重大误解，您有权取消订单，销售商亦有权自行取消订单，若您已经付款，则将为您办理退款。</b
        >
      </p>
      <p><b>10.配送服务</b></p>
      <p>
        您所订购的商品有两种配送方式：（1）由正品汇选择具有运输服务资质的公司为您提供运输代理服务；（2）由销售商选择具有
        提供运输服务资质的公司为您提供运输代理服务。
      </p>
      <p><b>11.交付</b></p>
      <p>
        11.1
        您所订购的商品将被送至订单表格上注明的送货地址。无论什么原因商品不能送达到送货地址的，销售商将会尽快跟您取得联系。假如从销售商第一次试图跟您联系之日7天内您没有提供答复，销售商取消该订单。
      </p>
      <p>
        11.2
        在您收到订购商品时，您书面签收或以其他合法方式与配送公司确认签收的则证明您收到了完好状态的商品。如果包装出现破损，请您拒收。
      </p>
      <p>
        <span
          >11.3您在本平台购买的商品由配送公司（包括跨越物流）为您完成订单交付的，系统或者单据记录的签收时间为交付时间；您购买的商品采用在线传输方式交付的，销售商向您指定系统发送的时间为交付时间；您购买服务的，生成的电子或者实物凭证中载明的时间为交付时间。</span
        >
      </p>
      <p>
        11.4
        如果送货单中的商品列表与您的订单表格中所记录的不同，或者您收到的商品与送货单中的商品列表不符，您可以按照本平台对外公布且正在实施的退货政策及退货程序，将相应的商品退回销售商。
      </p>
      <p><b>12.退回订单</b></p>
      <p>
        12.1
        如果您对您通过本平台所订购的商品不满意，您可以退回所有或部分商品。已经收取到的被退回的商品的价款，销售商将扣除退货运费后之后将相应的余额返还给您。您在下达的订单如果使用了代金券的，正品汇亦将会按照相应的活动规则进行返还。
      </p>
      <p>
        12.2
        您如果需要退货的，请务必于收到商品之日起7天内，按照不影响商品正常使用及再次销售的原则，将您需要退货的商品及其包装、赠品、送货单、税务发票（如有）等送货时一并交付给您的物品和资料，全部完好无损地退回销售商。否则，销售商有权不予退货。除有相反的证据外，退货的日期以销售商收到的包裹上显示的寄出邮戳所显示的日期为准。对于某些商品，如在商品详情页面及有关退货政策中已提示该类商品不予以退货的，您的下达订单行为将被视为您已同意接受销售商对此类商品不予退货的安排。
      </p>
      <p>
        12.3 销售商有权拒绝不符合正品汇公布实施的退货政策（参阅<a href="#"></a>
        ）所规定条件的所有退货。仅当销售商确认以上规定的这些条款得到了适时的遵从后，才会启动返还货款金额的程序。收到退货后7天内，所有的退款均遵循退换货政策操作。一旦实现退款，您将收到一封确认和终止程序的站内信息。
      </p>
      <p>
        12.4
        如果您的退货与上述退货规定不符，您将没有资格得到任何退款。不过，您仍有资格自行支付费用（包括运费）以便接收已经退回到销售商的商品。如果您不想接收已经退回到销售商的商品，销售商有权保有这些商品并保留已经收到的金额。
      </p>
      <p><b>13.商品保证</b></p>
      <p>
        本平台所展示的商品完全符合中华人民共和国的相关法律法规的规定。正品汇为了保障消费者合法权益，特在太平洋财产保险股份有限公司为正品汇售卖的商品进行了投保。如果您对在本平台所购买的商品品牌存在疑问，请及时联系正品汇在线客服或拨打客服热线电话0755-33297563，并提供订单信息，正品汇将帮助您进行全面调查。
      </p>
      <p><b>14.知识产权</b></p>
      <p>
        14.1
        正品汇对本平台的内容享有全部知识产权，包括但不限于：编码、商标、服务标志、商号、图形、美术品、照片、肖像、文字内容、音频片断、按钮图标、购买评价以及计算机软件、标识、数码下载、数据汇编都是正品汇或其内容提供者的财产，受到中华人民共和国知识产权相关法律法规的保护。您对其仅享有本协议约定范围内的临时的、可撤销的非专有使用权。
      </p>
      <p>
        14.2
        您仅在符合本平台使用目的的前提下被许可浏览和使用本平台，即以个人名义浏览信息和购买供个人使用商品的目的。其他方式的使用都是被严格禁止的，包括但不限于以下方式：复制、修改、销售、传送、再版、删除、添加、展览、记入或演示本平台的内容或以其他方式部分地或整体地非法使用本平台的内容，但经正品汇允许将本平台当中的资讯转发至微信朋友圈等第三方媒体的除外。
      </p>
      <p><b>15.用户守则</b></p>
      <p>
        15.1
        本平台与其他在线使用的互联网网站一样，您如果需要进行下载、安装、运行、登录或者使用，您至少必须具备一部可供上网的智能移动终端，并确保其能够通过互联网与本平台服务器软件进行实时的信息（即电子数据）交互，相应的上网流量费等相关费用由您自行承担。除非另有说明，正品汇存储在其服务器上的数据是正品汇和其会员之间交易的唯一有效证据。
      </p>
      <p>
        15.2
        本平台目前免费使用，正品汇有权对本平台进行升级及更新，自行设置收费的软件功能、费率标准、收费对象及/或收费时段。您如果不同意正品汇设置的软件功能、费率标准、收费对象及/或收费时段的，则应当立即停止使用本平台；您如果继续使用的，则视为您接受正品汇的上述设置，您应当遵照履行并向正品汇支付相应的费用。
      </p>
      <p>
        15.3
        您不得进行任何侵犯本平台知识产权的行为，或者进行其他的有损于正品汇或其他用户合法权益的行为，包括但不限于：
      </p>
      <p>（1）删除或修改本平台上的版权信息；</p>
      <p>（2）修改、复制和/或发行本平台；</p>
      <p>（3）在本平台当中内置各种插件程序或者其他的第三方程序；</p>
      <p>（4）进行编译、反编译、反向工程或者以其他方式破解本平台的行为；</p>
      <p>（5）使用本平台外挂和/或利用本平台当中的BUG来获得不正当的利益；</p>
      <p>
        （6）利用劫持域名服务器等技术非法侵入、破坏本平台的服务器软件系统，或者修改、增加、删除、窃取、截留、替换、抓取本平台的客户端和/或服务器软件系统中的数据，或者非法挤占本平台的服务器空间，或者实施其他的使之超负荷运行的行为；
      </p>
      <p>（7）假冒正品汇或签约商家在本平台当中发布任何诈骗或虚假信息；</p>
      <p>（8）利用本平台故意传播恶意程序或计算机病毒。</p>
      <p>
        15.4您充分理解到：正品汇可能会不定期地通过发布软件升级包或软件补丁、在线升级等方式平台进行更新。更新的过程中，正品汇可能通过互联网调取、收集您的PC端终端上的关于本平台的客户端软件版本信息、数据及其他有关资料，并自动进行替换改、删除和/或补充。此种行为是平台更新所必须的一种操作或步骤，您如果不同意正品汇此种操作，请您不要进行更新；您更新的行为即视为您同意正品汇进行此种操作。
      </p>
      <p>
        15.5
        您有权在本平台当中使用商品评价、问答、直播互动等与网络信息内容相关的功能，但您应确保所发布的网络信息内容真实、客观且不会侵犯任何第三方的著作权、肖像权、名誉权、隐私权等合法权利。您同意并承诺不得借助本平台用户评论功能发布任何广告，在本平台所发布的网络信息内容应符合正品汇《社区公约》&《用户社区行为规范》的规定。
      </p>
      <p>
        15.6
        您应当遵守国家有关法律法规，不得在本平台当中发表、转发或者传播含有下列违法内容信息：
      </p>
      <p>（1）反对宪法所确定的基本原则的；</p>
      <p>（2）危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的；</p>
      <p>（3）损害国家荣誉和利益的；</p>
      <p>
        （4）歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；
      </p>
      <p>（5）宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；</p>
      <p>（6）煽动民族仇恨、民族歧视，破坏民族团结的；</p>
      <p>（7）破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
      <p>（8）散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
      <p>（9）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
      <p>（10）侮辱或者诽谤他人，侵害他人合法权益的；</p>
      <p>（11）含有法律、行政法规禁止的其他内容的。 或不良内容信息：</p>
      <p>
        （1）使用夸张标题，内容与标题严重不符的；
        （2）炒作绯闻、丑闻、劣迹等的；
      </p>
      <p>（3）不当评述自然灾害、重大事故等灾难的；</p>
      <p>（4）带有性暗示、性挑逗等易使人产生性联想的；</p>
      <p>（5）展现血腥、惊悚、残忍等致人身心不适的；</p>
      <p>（6）煽动人群歧视、地域歧视等的； （7）宣扬低俗、庸俗、媚俗内容的；</p>
      <p>
        （8）可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；
      </p>
      <p>（9）其他对网络生态造成不良影响的内容。</p>
      <p>
        15.7
        您充分理解并完全同意：对您在本平台当中发表、转发或者传播的评论及其内容，包括但不限于视频、照片、文字内容，不论是否属于著作权法意义上的作品，正品汇均享有永久的、无期限及地域限制的、完全免费的使用权。而且，对于上述评论和/或内容，正品汇还享有下列各项权利，且实际行使时无须另行征得您的同意：
      </p>
      <p>
        （1）有权对其进行修改、编辑、汇编、改编、分发或者以其他的方式进行使用；
      </p>
      <p>（2）既有权使用其全部内容，也有权使用其中的某一部分或某几部分内容；</p>
      <p>
        （3）有权将其许可给任何第三方使用，被许可的第三方征得正品汇同意后，既可以将其使用于正品汇网站和/或其App等正品汇自有网络平台，也可以将其使用于被许可的第三方自有的网络平台。
      </p>
      <p>
        （4）有权单独以自己的名义，对未经正品汇许可而擅自使用上述评论和/或内容的第三方进行取证、证据保全、公证、提起诉讼和/或依法采取其他合法措施，以追究其法律责任。
      </p>
      <p>
        15.8
        个人账户是正品汇授权您使用正品汇平台用户管理系统的唯一凭证。正品汇所提供的代金券、积分、唯品币、折扣、推送等服务和优惠均通过用户管理系统发放，并且仅提供给获得授权的用户个人使用。您同意在符合法律法规及本条款规定的情况下使用个人帐户，<span>正品汇有可能在某些情况（包括但不限于您违反有关法律法规规定或本协议和/或其它公开规则，或者您严重违背社会公德、提供虚假注册身份信息、经正品汇判定认为存在恶意退货或不合常理的高退拒率等情形、或其他损害或经本平台判定认为可能损害正品汇利益的不正当行为等）下暂时冻结、永久冻结、修改、删除您的个人账户或者采取其他处理措施。</span>特别地，您了解并同意，正品汇用户管理系统中个人帐户、积分、唯品币、代金券等互联网产品及服务所有权归属正品汇，会员在满足正品汇公布的规则的前提下有权使用上述产品及服务。非经正品汇同意，您不得将正品汇各项产品及服务用于商业用途（例如：销售正品汇个人帐户、销售个人帐户下积分、正品币、代金券等）。在使用正品汇服务过程中，如果您或您的个人帐户刻意规避正品汇管理措施，或存在涉嫌欺诈、商业牟利、不恰当或不诚实地使用服务或者其他违反本协议和/或其他公开规则的行为（包括但不限于使用作弊软件获取积分及/或代金券及/或正品币、贩卖个人帐号、积分及/或代金券及/或正品币、盗号、协助盗号、非用于个人或家庭的合理消费等），正品汇有权拒绝为您继续提供服务，永久冻结您的个人帐户，并根据具体情况并有权对该等个人帐号中因上述手段而产生、获得的一切虚拟产品予以清零。
      </p>
      <p>
        <span
          >15.9
          如您的行为使正品汇及/或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿正品汇及/或其关联公司的上述全部损失。</span
        >
      </p>
      <p>
        <span
          >如您的行为使正品汇及/或其关联公司遭受第三人主张权利，正品汇及/或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。</span
        >
      </p>
      <p>
        <span
          >如因您的行为使得第三人遭受损失，正品汇及/或其关联公司出于社会公共利益保护或消费者权益保护目的，可自您的任何正品汇账户中划扣相应款项进行支付。</span
        >
      </p>
      <p>
        <span
          >15.10
          您充分理解并完全同意，本平台提供的均为数量有限且面向不特定用户的商品或服务，作为完全民事行为能力人，您在使用本平台进行订购商品或服务时，应尽基本的审慎注意义务，并合理使用您的合法权益，若您存在滥用自身权利、违反诚实信用原则等不当行为，可能会使其他用户的合理订购需求得不到满足，并不合理地增加本平台与其他用户的缔约和履约成本。则正品汇有权根据《违规订单处理规则》等公示的平台规则对您的不当行为进行处理。</span
        >
      </p>
      <p><b>16.免责声明</b></p>
      <p><b>16.1 关于本平台的免责声明</b></p>
      <p>
        16.1.1
        本平台与其他的在线使用的互联网网站一样，也会受到各种不良信息、网络安全和网络故障问题的困扰，包括但不限于：
      </p>
      <p>
        （1）其他用户可能会发布诈骗或虚假信息，或者发表有谩骂、诅咒、诋毁、攻击内容的，或者含有淫秽、色情、下流、反动、煽动民族仇恨等让人反感、厌恶的内容的非法言论；
      </p>
      <p>
        （2）其他用户可能会发布一些侵犯您或者其他第三方知识产权、肖像权、姓名权、名誉权、隐私权和/或其他合法权益的图片、照片、文字等资料；
      </p>
      <p>
        （3）面临着诸如黑客攻击、计算机病毒困扰、系统崩溃、网络掉线、网速缓慢、程序漏洞等问题的困扰和威胁。
      </p>
      <p>
        <span
          >16.1.2
          您充分理解到：本协议第16.1.1条所述的各种不良信息、网络安全和网络故障问题，并不是正品汇或者本平台所导致的问题，给您造成的任何损失，概由您自行承担，正品汇无须向您承担任何责任。</span
        >
      </p>
      <p>
        16.1.3
        您完全同意：除法律法规规定或者您与正品汇约定须提前通知的以外，正品汇有权单方面地中止或者终止本平台所提供的服务而无须提前通知您。该等中止、终止，可能是因为国家法律、法规、政策及国家机关的命令或者其他的诸如地震、火灾、海啸、台风、罢工、战争等不可抗力事件，还可能是因为您违反本协议第15.3条、第15.5条和/或第15.6条所约定的用户守则。
      </p>
      <p><b>16.2 关于商品销售的免责声明</b></p>
      <p>
        <b
          >16.2.1
          由于用不同手机终端设备观看时页面显示的差异，正品汇上促销的商品在图像和颜色方面可能跟真实的物品不尽一致。因此，所有显示的图片、视频和其他商品显示方法仅限于图示目的，在任何情况下不认为是合同的组成部分。假如供出售的商品跟网站上的描述不同，对您唯一的补救措施是根据本协议在没有使用的情况下退回该商品。</b
        >
      </p>
      <p>
        <b
          >16.2.2
          销售商保留根据市场价格波动随时修改上线商品价格的权利而无须事先通知您。在由于排版错误或销售商提供价格信息错误的情况下以不正确的价格列出来的商品，如果造成了重大误解，销售商有拒绝或取消任何对以不正确的价格列出来的商品所下订单的权利。</b
        >
      </p>
      <p>
        <b
          >16.2.3
          由于合理的或不可避免的送货延迟对您或第三方带来的任何损失，正品汇不负任何责任。在这种情况下，正品汇将尽快地把任何可预知的延迟情况通知您，并在此时请您选择是否取消订单。</b
        >
      </p>
      <p>
        <b
          >16.2.4
          正品汇上面陈列的货物完全符合中华人民共和国的相关法律法规的规定。正品汇直接与销售商合作，他们保证其商品是正宗的。您在本平台上找到的所有商品及商品描述均由销售商提供，仅由他们对其真实性负责。您应该系统性地参照货物描述找出他们的特征，对于任何种类的直接或间接的、实质或非实质的由于商品的使用造成的损失，正品汇概不负责。</b
        >
      </p>
      <p><b>17.个人信息保护</b></p>
      <p>
        17.1 正品汇可能通过本平台
        在您自愿选择服务或提供信息的情况下收集您的个人信息（简称“个人信息”），例如您的姓名、邮件地址、电话号码及其他身份信息等。我们有可能会保留一些用户的使用习惯信息，用于更好地了解和服务于用户。这些数据将有利于我们开发出更符合用户需求的功能、信息和服务。
      </p>
      <p>
        17.2
        正品汇通过本平台可能会收集一些特定的关于您所使用的机器的技术信息，可能会包括：IP地址、操作系统版本、浏览器版本、显示器分辨率、推荐网站等。这些信息将用于提供更好的用户使用体验，提供更方便的服务。
      </p>
      <p>
        <span
          >正品汇希望通过隐私权政策向您清楚地介绍正品汇对您个人信息的处理方式，因此建议您完整地阅读隐私权政策（点击此处），以帮助您更好地保护您的隐私权。</span
        >
      </p>
      <p>
        <b
          >17.3
          正品汇非常重视保护您的正品汇账号及密码、电话号码、地理位置、智能移动终端品牌及型号等个人信息，未经您同意，正品汇不会将其披露给无关的第三方，更不会将其公之于众，但因下列原因而披露给第三方的除外：</b
        >
      </p>
      <p><b>（1）基于国家法律法规的规定而对外披露；</b></p>
      <p>
        <b
          >（2）应国家司法机关、行政监管机关及其他有关机关基于法定程序的要求而披露；</b
        >
      </p>
      <p><b>（3）为保护正品汇或您的合法权益而披露；</b></p>
      <p><b>（4）在紧急情况，为保护其他用户及第三方人身安全而披露；</b></p>
      <p><b>（5）经您本人同意或应您的要求而披露。</b></p>
      <p>
        <b
          >17.4
          正品汇将使用各种安全技术和程序防止您及其他用户个人信息的丢失、不当使用、未经授权阅览或披露。但您充分理解到：由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。</b
        >
      </p>
      <p><b>18.通知与送达</b></p>
      <p><b>18.1联系方式</b></p>
      <p>
        您在注册成为正品汇平台用户，并接受正品汇平台服务时，您应该向正品汇提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。
      </p>
      <p>
        您在注册正品汇平台用户时生成的用于登陆正品汇平台接收站内信、系统消息的会员账号（包括子账号），也作为您的有效联系方式。
      </p>
      <p>
        <span
          >正品汇将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。</span
        >
      </p>
      <p><b>18.2送达</b></p>
      <p>
        正品汇通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知，包括但不限于在正品汇平台公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件，向您的账号发送系统消息以及站内信信息，在发送成功后即视为送达；以纸质载体发出的书面通知，按照提供联系地址交邮后的第五个自然日即视为送达。
      </p>
      <p>
        对于在正品汇平台上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件等现代通讯方式或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。您指定接收法律文书的手机号码、电子邮箱等联系方式为您在正品汇平台注册、更新时提供的手机号码、电子邮箱联系方式，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。
      </p>
      <p>
        您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。
      </p>
      <p>
        您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。
      </p>
      <p>
        你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。
      </p>
      <p><b>19.其他约定</b></p>
      <p>
        19.1
        正品汇保留随时地不需要任何理由地、单方面地修订本协议的权利。本协议一经修订即完全替代修订前的协议版本，并通过适当的方式向所有用户公开征求意见，您可以通过本平台或本平台指定的方式反馈您的意见。您应当及时关注和了解本协议的修订情况，如果您不同意修订后协议版本，请您立即停止使用本平台，否则即视同您同意并完全接受修订后的协议版本。
      </p>
      <p>
        <span
          >19.2
          正品汇有可能以消费者告知书、买家须知、温馨提示等形式，向您说明您在使用本平台购买商品时应当履行的本协议所约定的义务之外的其他义务，您亦应当仔细阅读并全面履行。上述说明如果与本协议相互冲突或者矛盾的，以上述约定为准；上述约定未涉及的内容，仍适用本协议。</span
        >
      </p>
      <p>
        19.3
        本协议各条款是可分的，所约定的任何条款如果部分或者全部无效，不影响该条款其他部分及本协议其他条款的法律效力。
      </p>
      <p>
        19.4
        本协议各条款的标题只是为了方便用户阅读而起到提示、醒目的作用，对本协议的解释及适用没有任何指引作用。
      </p>
      <p>
        19.5
        正品汇基于本协议及其补充协议的有效弃权必须是书面的，并且该弃权不能产生连带的相同或者类似的弃权。
      </p>
      <p>
        <span
          >19.6
          您通过本平台购买非商品的服务产品（例如旅游产品）的，您与正品汇之间的权利义务亦参照本协议的相关约定。</span
        >
      </p>
      <p>
        19.7由于互联网高速发展，您与正品汇签署的本协议列明的条款并不能完整罗列并覆盖您与正品汇所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此，<span
          >正品汇平台隐私权政策、正品汇平台规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。如您使用正品汇平台服务，视为您同意上述补充协议。</span
        >
      </p>
      <p>
        19.8
        您与正品汇均应当严格履行本协议及其补充协议所约定的各项义务，如发生争议或者纠纷，双方可以友好协商解决；协商不成的，任何一方均可向<span>本协议签订地有管辖权的人民法院</span>提起诉讼。
      </p>
      <p>
        19.9
        本平台的客户服务电话为：0755-33297563，您如对本协议条款有任何疑问或者需要帮助，可以通过上述电话与我们联系。
      </p>
      <p>
        19.10本协议及其补充协议签订地为广东省深圳市福田区，均受中华人民共和国法律、法规管辖。（完）
      </p>
    </div> -->
    <!-- 隐私政策 -->
    <!-- <div class="content" v-else-if="$route.params.name === 'PrivacyPolicy'">
      <p><b>正品汇隐私政策</b></p>
      <h3>简介及提示</h3>
      <p><b>【特别提示条款】</b></p>
      <p>
        您的信任对深圳市正品汇电子商务贸易有限公司开发运营的正品汇（“我们”）非常重要，我们深知个人信息对您的重要性，我们将按照法律法规要求，采取相应安全保护措施，尽力保护您的个人信息。您在使用我们的产品与/或服务前，请您务必先仔细阅读和理解《正品汇隐私政策》（“本隐私政策”），特别应重点阅读我们以<span>粗体或粗体下划线</span>标识的条款，确保您充分理解和同意后再开始使用。我们希望通过本隐私政策向您说明我们在您使用我们的产品与/或服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。如您阅读过程中有任何疑问，可通过本隐私政策中的联系方式咨询我们。
      </p>
      <br />
      <p>【版本更新日期】：2022年【06】月【01】日</p>
      <p>【版本生效日期】：2022年【06】月【30】日</p>
      <br />
      <p><b>【政策摘要】</b></p>
      <p>本政策主要向您说明：</p>
      <p>【适用范围】</p>
      <p>
        本隐私政策与您所使用的正品汇网站、客户端、小程序、线下店以及随技术发展出现的新形态向您提供的各项产品和服务（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本政策所写明的内容，据此您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策适用于我们的产品与/或服务的最新版本。如我们关联公司的产品或服务中使用了正品汇提供的产品或服务但未设置独立隐私政策的，则本隐私政策同样适用于该部分产品或服务。
      </p>
      <br />
      <p>【我们如何收集和使用您的个人信息】</p>
      <p>
        正品汇为您提供便捷的电子商务交易平台服务，为了实现服务的提供，我们主要会基于如下需要收集、使用、共享、存储您的个人信息：
      </p>
      <p>
        <b>会员注册：</b
        >您在访问我们的产品及服务时，可匿名浏览商品信息，但在您下单交易或享受其他会员服务时，需要您注册成为正品汇会员。
      </p>
      <p>
        <b>展示和推送商品和服务：</b
        >为帮助您快速找到您所需要的商品和服务，或在您搜索时向您展示您可能希望找到的商品，我们会通过技术方式结合您的行为偏好特征，向您推荐您可能需要的商品/服务。如您不需要本项服务，您可以在“个人中心”-“设置”-“隐私”-“个性化推荐设置”关闭个性化内容推荐。
      </p>
      <p>
        <b>展示和推送广告：</b
        >我们会通过技术方式结合您的行为偏好特征，向您提供更契合您需求的广告信息。如您不需要本项服务，您可以在“个人中心”-“设置”-“隐私”-“程序化广告设置”关闭程序化广告。
      </p>
      <p>
        <b>下单及交易履约：</b
        >您需要提供收货人、收货地址、联系电话信息以便于我们帮助您顺利完成交易并保障您的交易安全。
      </p>
      <p>
        <b>客服及争议处理：</b
        >在为您提供客服或争议纠纷处理服务时，我们需要收集和处理您的必要信息，用于验证您的身份、向您回复及回访我们服务人员的服务质量。
      </p>
      <p>
        <b>安全保障：</b
        >为保障您的账户及交易安全，我们可能需要收集和处理您的设备信息、日志信息，我们也会将这些信息用于预防发生各类违法违规或其他主体合法权益被侵害的情况。
      </p>
      <p>
        <b>其他附加功能：</b
        >为便于您实现购物、评价、分享、存储图片以及与客服沟通等服务需求，我们还为您提供了很多附加功能。例如使用您所在位置向您推荐您所在区域可以购买的商品或服务，您可按需选择使用或关闭。
      </p>
      <br />
      <p>
        对于上述您的个人信息，大部分是由您主动向我们提供的，也有我们在您使用我们的产品与/或服务时通过Cookies、SDK及类似技术获取的，还有部分是从第三方间接获取的。
      </p>
      <br />
      <p>【我们如何共享、转让、公开披露您的个人信息】</p>
      <p>
        请您理解，我们无法单独向您提供上述全部的服务，为满足向您提供上述服务的必要，我们需要向我们的关联公司、供应商、合作伙伴及其他第三方以共享或委托处理的方式提供您的部分个人信息，但仅限于与处理目的相关的必要范围。我们会采取必要的技术和管理措施尽可能地保护您的个人信息安全。
      </p>
      <br />
      <p>
        另外，我们也会根据您的选择，向与您交易的第三方商家或其他交易对象提供您的个人信息，以便其能够提供您所选择的商品或服务。
      </p>
      <br />
      <p>【您如何管理自己的信息】</p>
      <p>
        您可以通过多种方式管理您的个人信息，例如直接在个人中心查阅、更正、补充、删除您的个人信息，以及注销您的账户。如果您在管理您的个人信息时遇到问题，您还可以通过下方联系方式与我们联系，我们将在15天内回复您的要求。但也请您理解，我们可能会因为履行法律法规义务或者我们提供服务必要性的原因无法完全满足您的请求。
      </p>
      <br />
      <p>【未成年人个人信息保护】</p>
      <p>
        我们一直非常重视并致力于对未成年人个人信息的保护。如果您是未成年人，我们建议您和您的父母或其他监护人一起阅读本政策；如果您是14周岁以下儿童，在您使用我们的产品与/或服务前，您和您的父母或其他监护人应仔细阅读本隐私政策关于儿童个人信息保护的特殊规定，确保已取得父母或其他监护人对本隐私政策的同意后，您方可在父母或其他监护人的指导下使用我们的产品与/或服务，以确保您在使用我们的产品与/或服务和进行交易时的安全。
      </p>
      <br />
      <p>【联系我们】</p>
      <p>
        如您对本政策有任何疑问、意见或建议，或者您在管理您的个人信息时遇到任何问题，您可联系正品汇客户服务部进行初步咨询，客户服务部电话：075583829277。如您需要获取具体个人信息副本，您可以通过发送邮件至我们专门的个人信息保护邮箱dyn@zte-yd.com的方式与我们联系。
      </p>
      <br />
      <h3>《正品汇隐私政策》</h3>
      <p>【定义及适用范围】</p>
      <p>
        一、本隐私政策与您所使用的<span>正品汇网站、客户端、小程序、线下店以及随技术发展出现的新形态向您提供的各项产品和服务（以下统称“我们的产品与/或服务”）</span>息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本政策所写明的内容，据此您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策适用于我们的产品与/或服务的最新版本。<span
          >如我们关联公司的产品或服务中使用了正品汇提供的产品或服务但未设置独立隐私政策的，则本隐私政策同样适用于该部分产品或服务。</span
        >
      </p>
      <p>
        二、本隐私政策所称的关联公司是指：一方直接或间接控制、共同控制另一方，以及两方或两方以上受同一主体控制、共同控制的公司。前述“控制”是指，直接或间接拥有的权力，从而通过行使表决权、合同或其他方式决定或影响某一方的管理或决策方向。
      </p>
      <p>
        三、本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。您使用或在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的产品与/或服务，即意味着您同意本隐私政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。
      </p>
      <br />
      <p><b>本隐私政策将帮助您了解以下内容：</b></p>
      <br />
      <p>一、我们如何收集和使用您的个人信息</p>
      <p>二、我们如何使用 Cookie 和同类技术</p>
      <p>三、我们如何共享、转让、公开披露您的个人信息</p>
      <p>四、我们如何保护和保存您的个人信息</p>
      <p>五、您如何管理您的个人信息</p>
      <p>六、我们如何处理未成年人的个人信息</p>
      <p>七、您的个人信息如何在全球范围转移</p>
      <p>八、通知和修订</p>
      <p>九、如何联系我们</p>
      <br />
      <p>一、我们如何收集和使用您的个人信息</p>
      <p>
        个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。<b
          >本政策中对涉及到的敏感个人信息以粗体或加下划线方式进行显著标识。</b
        >
      </p>
      <br />
      <p>
        我们将遵循合法、正当、必要和诚信原则，在本政策范围内收集和使用您的个人信息：
      </p>
      <p><b>（一）您授权我们收集和使用您个人信息的情形</b></p>
      <p>
        我们的产品与/或服务包括一些核心功能，这些功能包含了实现网上购物所必须的功能、改进我们的产品与/或服务所必须的功能及保障交易安全所必须的功能。<b
          >我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：</b
        >
      </p>
      <br />
      <p><b>1、实现网上购物和交易安全所必需的功能</b></p>
      <br />
      <p><b>（1）帮助您成为我们的会员</b></p>
      <p>
        为成为我们的会员，以便我们为您提供会员服务，您需要提供<b>手机号码</b>，并创建用户名和密码。您使用第三方账号注册成为我们的会员或者登录会员的，仍需要您提供手机号码，用于创建正品汇账户或绑定您已有的正品汇账户。我们将通过发送短信验证码的方式来验证您的身份是否有效，通过SIM卡信息识别并显示对应的运营商。如果您仅需使用浏览、搜索等基本服务，您不需要注册成为我们的会员及提供上述信息。
      </p>
      <p>
        <span
          >在注册会员过程中，如果您提供以下额外信息补全个人资料，将有助于我们给您提供如会员生日特权、尺码推荐等更个性化的会员服务，包括您的昵称、头像、性别、尺码、出生年月日以及您的实名认证相关信息。如果您不提供这些信息，将会影响到您使用对应个性化的会员服务，但不会影响您继续使用正品汇产品或服务的基本浏览、搜索、购买功能。</span
        >
      </p>
      <br />
      <p>（2）为您展示和推送商品和服务</p>
      <p>
        <span
          >为了向您提供适配的页面展示，帮助您快速找到您所需要的商品和服务，以及给您匹配附近仓库可供购买的商品/服务，提供便捷的配送服务，我们会收集关于您使用的我们的产品与/或服务以及使用方式的信息并将这些信息进行关联。</span
        >这些信息包括：
      </p>
      <p>
        设备信息：我们会根据您在软件安装及使用中主动授予的具体权限，接收并记录您所使用的设备相关信息，包括设备名称、设备型号、设备MAC地址、操作系统和应用程序版本、语言设置、分辨率、移动应用列表（软件安装列表）、唯一设备识别码（IDFA/OPENUDID/GUID/OAID/
        ANDROIDID
        /UUID信息，以您所使用的设备及系统所支持的识别码为准）、运营商网络类型，设备所在位置相关信息（包括经授权的<span>GPS位置</span>以及能够提供相关信息的WLAN接入点、蓝牙和基站传感器信息）。
      </p>
      <p>
        日志信息：当您使用我们的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为有关网络日志保存，包括您的搜索记录、浏览器类型、浏览记录、订单信息、关注收藏及分享信息、IP地址、访问日期和时间及您访问的网页记录。
      </p>
      <p>
        <span
          >请注意，单独的设备信息、日志信息是无法识别特定自然人身份的信息。</span
        >如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
      </p>
      <p>
        <span
          >为了将您感兴趣的商品或服务信息展示给您，或在您搜索时向您展示您可能希望找到的商品，我们将根据您的上述设备信息、日志信息等提取您的偏好特征，进行数据分析以形成用户画像。</span
        >我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间互动时我们获得的相关信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议，并向您推送您可能感兴趣的商业广告及其他信息。上述做法可能会对您的商品浏览页面展示、商品搜索页面排序、商品推荐页面展示产生影响。
      </p>
      <p>
        <span
          >如果您不希望受个性化内容以及商业广告的影响，您可以通过如下方式保障您的体验：</span
        >
      </p>
      <p>
        ---如果您不想接受我们给您发送的商业广告，您可随时根据短信提示或我们提供的其他方式进行退订；
      </p>
      <p>---您可以在搜索框下方删除或管理您此前输入的搜索关键字信息；</p>
      <p>
        ---您可以在购物车、个人中心的“商品收藏”“品牌订阅”“我的足迹”管理或删除您的加购、收藏、订阅、浏览足迹信息；
      </p>
      <p>
        ---您可以通过长按被推荐的商品或服务图片，根据提示弹窗选择屏蔽相关商品、品牌或服务类目；
      </p>
      <p>
        ---您可以在商品展示页选择按照分类进行浏览和查找商品和服务，我们还在搜索结果中向您提供了按照价格、销量排序等不针对个人特征的选项；
      </p>
      <p>
        ---您可以在“个人中心”-“设置”-“隐私”-“个性化推荐设置”关闭个性化内容推荐，在设置关闭期间，我们为您展示的页面中仍将包含我们根据平台内商品或服务的整体受欢迎程度等情况随机提供的商品以及服务，与您的相关度会降低，可能会影响您的购物体验。
      </p>
      <br />
      <p><b>（3）下单</b></p>
      <p>
        当您准备对您购物车/购物袋内的商品进行结算时，正品汇系统会生成您购买该商品的订单。<span
          >您需要在订单中至少填写您的收货人姓名、收货地址以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息、您应支付的货款金额及支付方式；您可以另外填写收货人的固定电话、邮箱地址信息以增加更多的联系方式确保商品可以准确送达，但不填写这些信息不影响您订单的生成。若您需要开具发票，还需要提供发票抬头、纳税人识别号以及接收发票电子邮箱。对于部分特殊类型的商品和服务您还需要提供该商品或服务所必需的其他信息，例如涉及跨境电子商务零售商品交易时您需要提供您的身份证号以完成清关。</span
        >
      </p>
      <p>
        上述所有信息构成您的“订单信息”，我们将使用您的订单信息来进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务；我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。
      </p>
      <p>
        为了确保您的“订单信息”能实现上述服务与功能，我们需要您提供的“订单信息”是真实且准确的，如果您在订单生成后更改了“订单信息”，包括入线物流承运商更改等，您同意第三方将您修改后的“订单信息”完整的共享给我们。
      </p>
      <br />
      <p>（4）支付功能</p>
      <p>
        在您下单后，您可以选择与正品汇的关联方或合作的第三方支付机构（包括支付宝、正品支付、微信支付及云闪付，
        以下称“支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的<span>订单号与交易金额信息、订单安全相关设备信息</span>与这些支付机构共享以实现其确认您的支付指令并完成支付。如您选择由其他金融机构为您提供支付服务，我们还会将您的包括<span>银行卡号、有效期在内的银行卡（包括储蓄卡、信用卡）</span>支付必要信息与您选择的相应金融机构共享以完成支付。
      </p>
      <br />
      <p><b>（5）交付产品或服务功能</b></p>
      <p>
        为便于向您交付商品或服务，您需提供<span>真实的收货人姓名、收货地址、收货人联系电话。</span>如果我们委托第三方向您交付时，您同意将上述信息共享给第三方。如果您在订单生成后修改了上述信息，您同意正品汇和第三方均能共享上述信息。如果您拒绝提供或者不同意共享此类信息，我们将无法完成相关交付服务。
      </p>
      <p>
        <span
          >您可以通过正品汇为其他人订购商品或服务，您需要提供该实际订购人的前述个人信息。向正品汇提供该实际订购人的前述个人信息之前，您需确保您已经取得其授权同意。</span
        >
      </p>
      <br />
      <p><b>（6）客服与售后功能</b></p>
      <p>我们的电话客服和售后功能会使用您的账号信息和订单信息。</p>
      <p>
        为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更配送地址、联系人或联系电话。<span
          >您也可以通过我们提供自助方式进行操作退换货申请、上门揽退等服务，届时可能需要您提供寄件人信息（包括寄件人姓名、联系方式、地址）。</span
        >
      </p>
      <br />
      <p><b>2、为您提供安全保障</b></p>
      <br />
      <p>
        为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或正品汇相关协议规则的情况，<span
          >我们可能使用或整合您的会员信息、交易信息、设备信息（设备型号、设备Mac地址、应用进程列表、设备识别码、操作系统及软件版本、设备状态、网络状况）、日志信息、IP地址、位置信息、浏览信息、订单信息、常用软件列表、ssid、wifi信息、电池使用情况、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。我们还可能在应用程序中嵌入我们合作的第三方合作伙伴开发的应用安全类的软件工具开发包（在本隐私政策中简称“SDK”）收集您的设备信息、服务日志信息，以验证您的账户安全，保护您的财产权益免遭侵害。</span
        >
      </p>
      <p>
        3、产品业务功能所收集使用您的信息逐项列举，请查阅<a href="#"
          >《个人信息收集与使用清单》</a
        > 。
      </p>
      <br />
      <p><b>（二）您可选择是否授权我们收集和使用您的个人信息的情形</b></p>
      <br />
      <p>
        1、为提升您的购物体验和保障我们的产品/服务功能实现与安全稳定运行目的，我们可能会根据您选择的具体附加功能/服务申请或使用操作系统的相关权限。相应应用权限申请的目的、范围与使用情况说明请参见<a
          href=""
          >《APP权限使用情况列表》</a
        >。<b>如果您不同意授权APP获取相关操作系统权限，不会影响您使用我们提供的基本业务功能（基本业务功能所依赖的必要操作系统权限除外），但您可能无法获得附加服务给您带来的用户体验。</b>您可在您的设备设置或“个人中心-设置-隐私-系统权限设置”中逐项查看APP权限的状态，并可自行随时决定这些权限的开启或关闭。此外，我们还会基于剪贴板为您提供部分附加功能，当您复制唯口令、下载APP领券活动口令、填写地址信息时，您的剪贴板功能可能会被调用。剪贴板信息仅会在您的设备上进行处理，我们不会存储您的剪贴板信息用于任何其他途径。
      </p>
      <br />
      <p>
        2、上述附加功能我们会通过弹窗方式提示告知您我们所需要获取的权限。如您拒绝提供对应权限，在随后的48小时内您重新打开正品汇APP时，我们将不会主动地重复向您申请该权限以避免对您产生过于频繁的打扰（除非您主动需要使用特定功能）。如您是安卓用户，您可以通过移动设备-设置-权限管理逐项查看您APP权限的开启状态，并可以决定将这些权限随时的开启或关闭。如您是苹果用户，您可以通过移动设备-设置-隐私查看、开启或关闭APP权限。<span>请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。</span>除此以外，我们在相关业务功能中可能还需要您开启设备的其他访问权限，<a
          href=""
          >详细权限和使用目的请参见此处</a
        >。
      </p>
      <br />
      <p>
        <b>（三）以下情形，我们收集、使用您的个人信息，无需征得您授权同意：</b>
      </p>
      <br />
      <p>1、与国家安全、国防安全有关的；</p>
      <br />
      <p>2、与公共安全、公共卫生、重大公共利益有关的；</p>
      <br />
      <p>3、与刑事侦查、起诉、审判和判决执行等有关的；</p>
      <br />
      <p>
        4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
      </p>
      <br />
      <p>5、所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
      <br />
      <p>
        6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
      </p>
      <br />
      <p>7、根据您的要求签订合同所必需的；</p>
      <br />
      <p>
        8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；
      </p>
      <br />
      <p>9、为合法的新闻报道所必需的；</p>
      <br />
      <p>
        10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
      </p>
      <br />
      <p>11、法律法规规定的其他情形。</p>
      <br />
      <p><b>（四）我们从第三方获得您个人信息的情形</b></p>
      <br />
      <p>
        当您通过第三方账户直接登录并使用我们的产品与/或服务时，我们可能从第三方获取您授权共享的账户信息（<span>头像、昵称、手机号、邮箱号及授权页面提示的信息</span>），并在您同意本隐私政策后将您的第三方账户与您的正品汇账户绑定。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
      </p>
      <br />
      <p><b>（五）您个人信息使用的规则</b></p>
      <br />
      <p>
        1、我们会根据本隐私政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。
      </p>
      <br />
      <p>
        2、在收集您的个人信息后，我们会通过技术手段对您的个人信息进行去标识化处理。
      </p>
      <br />
      <p>
        <span
          >3、请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。</span
        >
      </p>
      <br />
      <p>
        4、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势，但这些统计信息不包含您的任何身份识别信息。
      </p>
      <br />
      <p>
        5、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
      </p>
      <br />
      <p>
        6、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。<span
          >请您理解，我们向您提供的产品与/或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务。</span
        >
      </p>
      <br />
      <p><b>二、我们如何使用 Cookie 和同类技术</b></p>
      <br />
      <p><b>（一）Cookie</b></p>
      <br />
      <p>
        1、为确保网站正常运转，为您获得更轻松的访问体验，向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上储存名为Cookie的小数据文件，Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，网站能够储存您的偏好或购物车/购物袋内的商品等数据。
      </p>
      <br />
      <p>
        2、您可根据自己的偏好管理或删除Cookie。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时更改用户设置，也可能导致部分功能无法正常使用。如需了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
      </p>
      <br />
      <p><b>（二）网站信标和像素标签</b></p>
      <br />
      <p>
        除Cookie外，我们还会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，可以随时从我们的寄信名单中退订。
      </p>
      <br />
      <p><b>（三）第三方SDK、插件</b></p>
      <p>
        为保障我们的产品与/或服务稳定运行以及您能够充分享受和使用我们的产品与/或服务的必需、附加功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。如您使用相关支付和金融服务所必需的人脸识别功能，旷视科技或腾讯云的SDK需要您打开设备的摄像头功能，用于进行实名验证、核验身份。我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并对关键的第三方执行信息安全审计，以验证第三方的个人信息安全保护措施是否满足正品汇的相关要求。我们接入的授权合作伙伴所使用的SDK请参见此处<a
          href=""
          >《第三方SDK收集个人信息清单》 </a
        >。对于微信小程序所使用的插件，您可以通过进入小程序，依次点击右上角“...”符号--左下角的小程序名称--“更多资料”，来查看服务商信息。
      </p>
      <br />
      <p><b>三、我们如何共享、转让、公开披露您的个人信息</b></p>
      <br />
      （一）共享
      <br />
      <p>
        1、我们不会与正品汇以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
      </p>
      <br />
      <p>
        （1）在获取单独同意的情况下共享：获得您的单独同意后，我们会与其他方共享您的个人信息。
      </p>
      <br />
      <p>
        （2）在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
      </p>
      <br />
      <p>
        （3）在法律法规允许的范围内，为维护正品汇、正品汇的关联方或合作伙伴、您或其他正品汇用户或社会公众利益、财产或安全免遭损害而有必要提供。
      </p>
      <br />
      <p>
        （4）只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务。
      </p>
      <br />
      <p>（5）应您需求为您处理您与他人的纠纷或争议。</p>
      <br />
      <p>
        （6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供。
      </p>
      <br />
      <p>（7）基于学术研究而使用。</p>
      <br />
      <p>（8）基于符合法律法规的社会公共利益而使用。</p>
      <br />
      <p>
        2、<span
          >基于向您完整提供我们的产品和服务的需要，我们可能会将您的个人信息与我们的关联方或关联产品共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明范围和目的的约束。我们的关联方如要改变个人信息的处理目的、范围，将再次征求您的授权同意。</span
        >
      </p>
      <p>
        （1）当您购买的是正品汇自营的商品或服务时，正品汇的关联方将需要依法为您开具相应发票，为此需要将您的<span>订单号、订单商品名称、商品数量以及商品金额信息</span>共享给相应的开票关联方。关于开具发票的正品汇关联方，您可以通过正品汇平台公示的经营主体证照进行查询；您也可以在交易完成后通过“我的订单”—“订单详情”—“发票信息”进行查阅具体的开票方。
      </p>
      <br />
      <p>
        （2）当您通过正品汇APP使用分享推广或商品收藏功能时，为使您更便捷推广您感兴趣的商品、查阅您所推广过的商品记录以及推广收益，我们将向正品汇旗下的唯享客共享您的<span>推广历史、商品收藏信息</span>，若有用户通过您的推广产生交易订单的，<span>相应的交易订单号以及收益金额</span>也将共享给正品汇。更多的功能（如推广位管理、收益提现），您可以通过下载登录正品汇APP了解。
      </p>
      <p>
        3、与授权合作伙伴共享：<span>仅为实现本隐私政策中声明的目的，我们的某些服务将由我们和授权合作伙伴共同提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。</span>例如，在您上网购买我们的产品时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。<span>我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。</span>目前，我们的合作伙伴包括以下类型：
      </p>
      <br />
      <p>
        （1）商品或技术服务的供应商。我们可能会将您的个人信息共享给支持我们功能的第三方。这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与/或服务的核心购物功能，比如我们必须与物流服务提供商共享您的订单信息才能安排送货（<b>您可以通过“我的订单”—“订单详情”实时查阅具体服务方信息</b>）；或者我们需要将您的<span>订单号和订单金额</span>与第三方支付机构共享以实现其确认您的支付指令并完成支付等。
      </p>
      <br />
      <p>
        （2）第三方商家。<span
          >我们必须将您的订单信息与交易有关的必要信息（包括商品名称、订单号、金额、数量、收货人姓名、收货地址、收货人联系方式）与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。为向您提供售后与争议解决服务之目的，您同意我们可自上述服务方收集与物流服务相关的信息。</span
        >
      </p>
      <br />
      <p>
        （3）广告与推广的合作伙伴。当合作伙伴向使用我们产品与/或服务的用户群提供促销推广的服务时，<span
          >我们会向这些合作伙伴提供不能识别你个人身份的间接画像标签及去标识化的设备信息或匿名化后的设备、网络、渠道信息，并共享去标识化的统计分析信息，以提供程序化广告服务。这些信息仅为提高推广的覆盖面以及有效触达率，不会识别您的真实身份或与您的真实身份相关联。我们也会与第三方的平台或媒体合作基于您的个人信息向您推荐个性化的商品或服务。这些个人信息来源于您在使用我们产品与/或服务过程中的行为数据及其间接画像，该过程中我们不会向平台或媒体提供可用于识别你个人身份的信息。如您不愿意接受平台或媒体的程序化广告服务，您可以联系对应平台或媒体进行关闭。您也可以在“个人中心”-“设置”-“隐私”-“程序化广告设置”关闭程序化广告，在设置关闭期间，我们为您展示的页面中的广告数量不会变化，但是广告相关性会降低，可能会影响您的购物体验。</span
        >
      </p>
      <br />
      <p>
        （4）合作金融机构或其他合作伙伴。当您通过正品汇平台使用消费金融服务时，基于反洗钱等相关法律法规规定以及签署、履行服务合同的要求，<span>您需要提供姓名、身份证或其他身份证明（如护照）、银行卡号及其绑定手机号、人脸信息、常用地址、联系人信息，并将其共享给提供服务的第三方，根据提供服务的第三方具体要求，您还可能需要主动提供职业、婚姻状况、最高学历、居住状况、月收入、单位名称、单位地址、单位电话信息。您在使用该等服务时，我们会充分地向您明示，仅当获得您的单独同意后，我们方会向该服务提供方共享您的个人信息。</span>有关人脸核身服务的内容及人脸信息的处理规则请参见<a
          href=""
          >《人脸识别授权协议》 </a
        >。
      </p>
      <br />
      <p>
        4、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护正品汇、您或其他正品汇用户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过，这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
      </p>
      <br />
      <p>
        5、我们将您的个人信息所共享的第三方主体、内容及目的请参见此处。<a
          href=""
          >《与第三方共享个人信息基本情况》 </a
        >
      </p>
      <br />
      <p>
        6、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。在敏感个人信息使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户个人信息。
      </p>
      <br />
      <br />
      <p><b>（二）转让</b></p>
      <br />
      <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
      <br />
      <p>
        1、在获取单独同意的情况下转让：获得您的单独同意后，我们会向其他方转让您的个人信息；
      </p>
      <br />
      <p>
        2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
      </p>
      <br />
      <p>
        3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
      </p>
      <br />
      <p>
        <b
          >4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。</b
        >
      </p>
      <br />
      <p><b>（三）公开披露</b></p>
      <br />
      <p>我们仅会在以下情况下，才会公开披露您的个人信息：</p>
      <br />
      <p>
        1、获得您单独同意或基于您的主动选择，我们可能会公开披露您的个人信息；
      </p>
      <br />
      <p>
        2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。
      </p>
      <br />
      <p><b>四、我们如何保护和保存您的个人信息</b></p>
      <br />
      <p><b>（一）个人信息保护的技术与措施</b></p>
      <br />
      <p>1、数据安全技术措施</p>
      <p>
        我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会使用加密技术（如TLS、AES等）、脱敏技术和匿名化技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；部署数据安全监控机制，尽力确保数据在数据生命周期（数据采集、存储、传输、使用、共享和销毁）中得到安全保障。
      </p>
      <br />
      <p>2、其他安全措施</p>
      <p>
        我们通过建立专门的管理制度、流程和组织以保障个人信息的存储和使用安全。
      </p>
      <p>
        我们通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
      </p>
      <p>
        我们建立信息安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。
      </p>
      <p>
        加强安全意识。我们建立了信息安全培训体系，通过多种手段对全体员工进行信息安全意识培训，提高员工的信息安全意识以及隐私安全意识，保障会员个人信息安全。
      </p>
      <p>
        安全认证。正品汇网站和支付系统均已获得公安部颁发的安全等级保护三级认证，同时正品汇也达到国际信息安全管理体系权威认证的要求，获得ISO
        27001：2013认证。
      </p>
      <br />
      <p>
        3、我们仅允许有必要知晓这些信息的正品汇及正品汇关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与正品汇的合作关系。
      </p>
      <br />
      <p>
        4、我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。
      </p>
      <br />
      <p>
        5、我们大力强化对正品汇第三方物流员工的数据安全培训和要求，提高物流员工保护用户隐私数据的意识；每年定期对其进行信息安全巡检，确保信息安全保障措施满足安全要求。
      </p>
      <br />
      <p>
        6、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。
      </p>
      <br />
      <p>
        7、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
      </p>
      <br />
      <p>
        8、安全应急响应。当您在正品汇网站与第三方进行网上商品或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
      </p>
      <p>
        针对个人信息泄露、毁损、丢失等安全风险，我们建立了安全应急响应机制和应急响应团队，从制度层面规范安全事件处置的要求和流程。对安全事件实施分类分级，针对不同事件启动有针对性的安全应急预案，进行事件检测、抑制、根除、恢复、制定补救措施，联合相关部门进行溯源和打击。
      </p>
      <br />
      <p>
        9、在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动向监管部门上报个人信息安全事件的处置情况。
      </p>
      <br />
      <p>
        10、如果您对我们的个人信息保护有任何疑问，可通过本政策最下方约定的联系方式联系我们。如您发现自己的个人信息泄露，尤其是您的账户及密码发生泄露，请您立即通过本政策最下方【如何联系我们】约定的联系方式联络我们，以便我们采取相应措施。
      </p>
      <br />
      <p><b>（二）个人信息的保存</b></p>
      <br />
      <p>
        1、您的个人信息将被存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。
      </p>
      <br />
      <p>
        2、<span
          >请您注意，当您成功申请注销正品汇账户后，我们将对您的个人信息进行删除或匿名化处理。</span
        >
      </p>
      <br />
      <p>
        3、<span
          >如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</span
        >
      </p>
      <p>
        <span
          >4、我们只会在实现本隐私政策所述目的所需的期限内保留您的个人信息，法律有强制的留存要求的除外，例如《电子商务法》规定商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。</span
        >
      </p>
      <br />
      <p><b>五、您如何管理您的个人信息</b></p>
      <br />
      <p>您可以通过以下方式访问及管理您的个人信息：</p>
      <br />
      <p>1、查阅和更正您的个人信息</p>
      <br />
      <p>
        您有权随时查阅和更正您的个人信息，法律法规规定的例外情况除外，您可以通过以下方式查阅和更正您的个人信息：
      </p>
      <br />
      <p>
        （1）账户信息：PC端您可以在“我的正品汇”页面的“我的账户”菜单中查阅您提交给正品汇的所有个人信息（包括基本资料、尺码信息），您也可通过上述途径更新除实名认证信息之外的其他个人信息（您的实名认证信息是您通过实名认证时使用的姓名和身份证信息）；也可以在“我的正品汇”主页通过“账户安全级别”右侧提示进入“安全中心”设置您的登录名，修改您的绑定的手机号、验证邮箱、设置安全问题，查看您的身份认证信息。
      </p>
      <p>
        在移动端，您可以在“个人中心—右上角齿轮图标—隐私设置—个人信息查看与导出”界面查阅您的个人信息，您可以通过“个人中心—点击正品汇会员头像”或“个人中心—右上角齿轮图标—正品汇会员”设置您的个人资料（包括头像、昵称、收货地址、生日、形象以及身高、体重、肩宽、胸围、腰围、臀围相关尺码信息）；您还可以通过“个人中心—右上角齿轮图标—账户与安全”设置您的登录名，修改您的绑定的手机号、验证邮箱，查看您的实名认证信息，管理您的关联账号信息；若修改您的生日信息，可能需要您联系我们客服处理才能更改。
      </p>
      <br />
      <p>
        （2）订单信息：PC端您可以通过访问“我的正品汇”页面的我的交易菜单中的“订单管理”查看您所有订单及其状态（包括已完成、已取消的）；移动端您可以通过“个人中心--我的订单-查看全部订单”进行查看。<span>您可以选择删除已经完成和已取消的订单来删除您的订单信息，则您将无法对删除的订单使用我们的晒单和评价功能，同时可能影响我们判断您的购买信息而难以提供相应的售后服务。</span>您还可以在已完成订单下，通过“发票详情”查看您的发票信息或修改您发票的接收邮箱信息。
      </p>
      <p>
        （3）在移动端，您可以通过“个人中心”的“商品收藏”“品牌订阅”“我的足迹”管理您的收藏、订阅、浏览足迹信息。
      </p>
      <br />
      <p>
        （4）您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。
      </p>
      <br />
      <p>
        （5）无法查阅和更正的个人信息：除上述列明的信息外，您的部分个人信息我们还无法为您提供查阅和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用附加功能时产生的非您主动提交的个人信息。上述信息我们会在您的授权范围内进行使用，您无法查阅和更正，但您可联系我们进行删除或做匿名化处理。
      </p>
      <br />
      <p>2、删除您的个人信息</p>
      <br />
      <p>
        您在我们的产品与/或服务页面中可以直接清除或删除的信息，包括订单信息、收货地址信息；
      </p>
      <br />
      <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
      <br />
      <p>（1）如果我们处理个人信息的行为违反法律法规；</p>
      <br />
      <p>（2）如果我们收集、使用您的个人信息，却未征得您的同意；</p>
      <br />
      <p>（3）如果我们处理个人信息的行为违反了与您的约定；</p>
      <br />
      <p>（4）如果您注销了正品汇账户；</p>
      <br />
      <p>（5）如果我们终止服务及运营；</p>
      <br />
      <p>（6）如果我们保存您个人信息的期限已届满。</p>
      <br />
      <p>
        若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
      </p>
      <br />
      <p>3、<b>改变您授权同意的范围或撤回您的授权</b></p>
      <br />
      <p><b>您可以通过以下方式改变或者撤回您的授权：</b></p>
      <br />
      <p>
        （1）<span
          >您可以通过删除信息、关闭设备功能、在正品汇网站或软件中进行“隐私设置”等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。</span
        >
      </p>
      <br />
      <p>
        （2）<span>
          根据您所使用的具体服务，可以通过发送邮件到dyn@zte-yd.com进行相关请求及操作。我们将会在您做出请求后的合理时间内处理您的请求，并且会根据您的请求，在此后不再收集、使用和/或披露您的个人信息。</span
        >
      </p>
      <br />
      <p>
        （3）<span
          >您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。</span
        >
      </p>
      <br />
      <p>
        请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
      </p>
      <br />
      <p>4、个人信息主体注销账户</p>
      <br />
      <p>
        <span
          >您可以通过PC端“客户服务—服务中心—注销账户”，或者在移动端APP“个人中心—右上角齿轮图标—账户与安全—注销账户”直接申请注销账户。关于您注销账户的方式以及您应满足的条件，请详见<a
            href=""
            >《正品汇账户注销须知》 </a
          >，您注销账户后，我们将停止向您提供产品与/或服务，除法律法规另有规定外，我们将删除您的个人信息。</span
        >
      </p>
      <br />
      <p>5、如果您不想接受我们给您发送的促销信息，您随时可通过以下方式取消：</p>
      <br />
      <p>
        （1）您可通过PC端账户设置页面的邮件订阅菜单中取消邮件订阅的促销信息。
      </p>
      <br />
      <p>（2）您可以随时回复“TD”来取消我们给您发送的手机促销短信。</p>
      <br />
      <p>
        （3）您可以通过移动端APP“个人中心-右上角齿轮图标-接收通知”设置是否接受我们通过“通知”推送给您的商品和促销信息。
      </p>
      <br />
      <p>
        （4）为了保护您的隐私，我们不会以任何方式和途径向您推送涉及宗教信仰、性、疾病等相关敏感内容的促销或商品信息给您。
      </p>
      <br />
      <p>6、个人信息主体获取个人信息副本。</p>
      <br />
      <p>
        您有权获取您的个人信息副本，您可以通过以下方式自行操作：打开正品汇App
        “个人中心—右上角齿轮图标—隐私设置—个人信息查看与导出”界面导出您的个人信息副本。<span
          >如您需要获取我们收集的您的其他个人信息副本，在符合相关法律规定且技术、成本可行的前提下，我们将根据您的要求依法向您提供。为确保您的信息安全，您向我们提出请求时应向我们提供必要的基础信息用于初步核验您的真实身份，并核验请求人和注册用户之间的同一性。</span
        >
      </p>
      <br />
      <p>7、响应您的上述请求</p>
      <br />
      <p>
        如果您无法通过上述方式查阅、更正或删除您的个人信息，或您需要查阅、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息，或您认为正品汇存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以通过本协议下方的方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
      </p>
      <br />
      <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
      <br />
      <p>（1）与国家安全、国防安全有关的；</p>
      <br />
      <p>（2）与公共安全、公共卫生、重大公共利益有关的；</p>
      <br />
      <p>（3）与犯罪侦查、起诉和审判等有关的；</p>
      <br />
      <p>（4）有充分证据表明您存在主观恶意或滥用权利的；</p>
      <br />
      <p>（5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>
      <br />
      <p><b>六、我们如何处理未成年人的个人信息</b></p>
      <br />
      <p>
        1、正品汇非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，应事先取得您监护人的同意。正品汇根据国家相关法律法规的规定保护未成年人的个人信息。
      </p>
      <br />
      <p>
        2、我们不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
      </p>
      <br />
      <p>
        3、如果有事实证明未成年人在未取得监护人同意的情况下注册使用了我们的产品与/或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。
      </p>
      <br />
      <p>
        4、对于可能涉及的不满14周岁的儿童个人信息，我们进一步采取以下措施予以保障：
      </p>
      <br />
      <p>
        （1）对于收集到的儿童个人信息，我们除遵守本隐私政策关于用户个人信息的约定外，还会秉持合法正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。
      </p>
      <br />
      <p>
        （2）当您作为监护人为被监护的儿童选择使用正品汇相关服务时，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。在具体服务中需要向您收集儿童个人信息的，我们会事先取得您的授权同意，并告知您收集的目的和用途。如果您不提供前述信息，您将无法享受我们提供的相关服务。此外，您在使用晒单、评价功能分享商品相关信息时可能会主动向我们提供儿童个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。若儿童本人需要注册或使用我们的产品与/或服务，您应正确引导并予以监护。
      </p>
      <br />
      <p>
        （3）儿童或其监护人有权随时查阅和更正儿童个人信息，还可以向我们提出更正和删除的请求。您可以通过阅读<a
          href=""
          >《正品汇儿童个人信息保护规则》 </a
        >了解更具体的内容。如您对儿童个人信息相关事宜有任何意见、建议或投诉、举报，请联系我们。我们会随时为您提供帮助。
      </p>
      <br />
      <p><b>七、您的个人信息如何在全球范围转移</b></p>
      <br />
      <p>
        原则上，我们在中华人民共和国境内运营中收集和产生的个人信息，将存储在中华人民共和国境内，以下情形除外：
      </p>
      <br />
      <p>（1）适用的法律有明确规定；</p>
      <br />
      <p>（2）获得您的单独同意；</p>
      <br />
      <p>（3）您通过互联网进行跨境交易等个人主动行为。</p>
      <br />
      <p>
        针对以上情形，我们会确保依据本隐私政策对您的个人信息提供足够的保护。
      </p>
      <br />
      <p><b>八、通知和修订</b></p>
      <br />
      <p>
        1、为给您提供更好的服务以及随着正品汇业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在正品汇网站、正品汇移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问正品汇以便及时了解最新的隐私政策。
      </p>
      <br />
      <p>
        2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。
      </p>
      <br />
      <p>本政策所指的重大变更包括但不限于：</p>
      <br />
      <p>
        （1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
      </p>
      <br />
      <p>
        （2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
      </p>
      <br />
      <p>（3）个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <br />
      <p>（4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <br />
      <p>
        （5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
      </p>
      <br />
      <p>（6）个人信息安全影响评估报告表明存在高风险时。</p>
      <br />
      <p>3、我们还会将本策略的旧版本存档，供您查阅。</p>
      <br />
      <p>
        4、<span
          >为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用我们的服务，即代表您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。我们鼓励您在每次使用我们服务时都查阅本政策。您可以在我们的产品和服务中通过“我的--设置--隐私--隐私政策”中查看本政策。</span
        >
      </p>
      <br />
      <p><b>九、如何联系我们</b></p>
      <br />
      <p>
        1、如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请联系正品汇客户服务部进行初步咨询，客户服务部电话：0755-33297563。如您需要获取具体个人信息副本，您可以通过发送邮件至我们专门的个人信息保护邮箱dyn@zte-yd.com的方式与我们联系。
      </p>
      <br />
      <p>
        2、正品汇平台运营者为深圳市正品汇商务贸易有限公司，注册地址为深圳市福田区沙头街道深南大道6029号世纪豪庭大厦9楼。
      </p>
      <br />
      <p>
        3、一般情况下，我们将在十五天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报，或<span
          >通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。</span
        >
      </p>
    </div> -->
    <!-- 用户服务协议 -->
    <!-- <div
      class="content"
      v-else-if="$route.params.name === 'UserServiceAgreement'"
    >
      <p><b>正品支付用户服务协议</b></p>
      <br />
      <p>
        正品支付用户服务协议（以下简称“本协议”）项下的服务（以下称“本服务”或“正品支付服务”）是由深圳市正品汇电子商务贸易有限公司（以下简称“本公司”或“我们”）为符合条件的注册用户（以下简称“用户”或“您”）所提供的支付服务。
      </p>
      <p>
        请您仔细阅读本协议，<b>特别是以粗体标注的相关条款，</b>该条款或是含有免除或者限制合同另一方责任的内容；或是我们希望您特别留意并知悉确认的内容。如您不同意协议内容或者无法准确理解条款含义，请不要进行后续操作，包括但不限于点击确认同意本协议及使用本协议项下服务。您可拨打客服电话进行咨询，以便我们为您解释和说明。
      </p>
      <p>
        <b
          >一旦您勾选例如“我已阅读并同意”链接且进行提交后，即意味着您已充分理解本协议的全部条款的含义及相应的法律后果，同意与本公司以数据电文形式订立本协议。</b
        >
      </p>
      <br />
      <p><b>一、声明和保证</b></p>
      <p>
        1、主体地位。请您在接受本协议或者以其他本公司允许的方式实际使用本服务之前，请确保您是适格的合同主体，并在清楚理解本协议内容及其法律后果后自行决定是否同意签署。
      </p>
      <p>
        2、法律效力。您知悉并确认，您勾选例如“我已阅读并同意”链接后，本协议即生效，本协议生效后对本公司及您均具有法律约束力。
      </p>
      <p>
        3、特殊保证。如您为无民事行为能力人或为限制民事行为能力人，且您勾选了例如“我已阅读并同意”之链接，则视为您的监护人同意您订立并使用本服务。同时，也请告知您的监护人，并在您监护人的指导下阅读本协议和使用正品支付服务。
      </p>
      <br />
      <p><b>二、术语定义</b></p>
      <p>
        1、
        正品支付会员号（或称“会员号”）：指您按照本公司提供的方式取得的，供您使用相关正品支付服务的编号。会员号登录名可以是您的电子邮箱，也可以是您的手机号码或其他编号。如您只持有会员号而没有正品支付账户，则不能使用本协议中的余额服务（见下文定义）。
      </p>
      <p>
        2、
        正品支付账户（或称“账户”）：指您获得正品支付会员号且通过本公司身份验证后，由您申请并确认取得的供您使用正品支付服务的支付账户。您取得正品支付账户后，会员号登录名即成为您正品支付账户登录名。除本协议另有约定外，正品支付账户持有人或被该持有人授权的人可以使用余额服务。
      </p>
      <p>
        3、
        正品支付登录名：指您注册正品支付会员号或正品支付账户时提供的用于登录的手机号或电子邮箱等，分为会员号登录名和账户登录名两种。
      </p>
      <p>
        4、
        身份要素:指本公司用于识别您身份的信息要素，包括但不限于您的会员号、正品支付账户、密码、数字证书、短信校验码、电话号码、手机号码、身份证件号码及指纹信息、人脸信息等。
      </p>
      <p>5、 本网站：除本协议另有说明外，指本公司官网www.zph.realbrand.com。</p>
      <p>
        6、
        正品汇平台：指正品汇电子商务平台（包括但不限于网站www.zph.readbrand.com、正品汇APP、微信小程序等电子信息平台）。
      </p>
      <p>
        7、
        正品汇：指正品汇平台运营方及与平台运营关联的其他正品汇集团下属关联方的统称。
      </p>
      <p>
        8、
        有权机关：指依照法律法规等有权要求查询、冻结或扣划资金等措施的司法机关及行政机关，包括但不限于公安机关、检察院、法院、海关、税务机关等。
      </p>
      <p>
        9、
        冻结：指有权机关要求的冻结。被冻结余额不能用于支付、提现或转账等，被冻结账户不可登录、使用。
      </p>
      <p>
        10、
        限制：指除冻结情况之外，正品支付会员号或账户部分或全部功能不能使用，以及会员号或账户相关资产、账户余额不能使用。
      </p>
      <p>
        11、
        止付：限制措施的一种，指正品支付账户余额不能使用，例如不能用于支付、提现或转账等服务。
      </p>
      <p>
        12、
        余额服务：指基于正品支付账户余额可以使用的充值、消费、收款、转账等服务。余额服务中收付款额度将按照《非银行支付机构网络支付业务管理办法》及其他监管规定确定。
      </p>
      <br />
      <p><b>三、正品支付服务内容 </b></p>
      <p>（一）支付账户开立</p>
      <p>
        1、
        我们将根据您的申请及《非银行支付机构网络支付业务管理办法》及其他监管规定要求，为您开立正品支付账户，并提供相关必要的信息查询、技术支持等服务，具体以我们实际提供的服务内容为准。
      </p>
      <p>
        2、
        您申请开立支付账户的，应在我们提供的相关网络页面或客户端页面如实填写、提供您的真实身份信息。
      </p>
      <p>
        3、
        我们将按照实名制管理要求，登记并采取有效措施验证您的身份信息，按照监管规定核对有效身份证件并留存前述信息，建立相应的身份识别体系，确保在有效核实您的身份信息及您的真实意愿后，为您开立正品支付账户。
      </p>
      <p>
        <b
          >您知悉并同意，我们可能自行也可能委托专业提供实名验证/技术服务的第三方通过权威数据源对您提交的身份信息进行验证，您授权我们及我们的前述合作机构可以为验证发起之故向合法留存您信息的第三方（包括权威数据源）提交用于验证的信息并收集、使用、储存相应的验证结果。
        </b>
      </p>
      <p>
        4、
        我们将依据您身份核验方式的不同及您自身的申请意愿为您开立相应余额付款功能的支付账户，具体详见下表所示：
      </p>
      支付账户类别 身份核验方式 支付账户余额付款功能 支付账户余额付款限额
      I类账户 以非面对面方式通过至少一个外部渠道验证身份 消费、转账
      自账户开立起累计不超过1000元（包括支付账户向您本人的同名银行账户转账）
      II类账户
      自主或委托合作机构以面对面验证身份，或以非面对面方式通过至少三个外部渠道验证身份
      消费、转账 年累计不超过10万元（不包括支付账户向您本人的同名银行账户转账）
      III类账户
      自主或委托合作机构以面对面验证身份，或以非面对面方式通过至少五个外部渠道验证身份
      消费、转账、投资理财
      年累计不超过20万元（不包括支付账户向您本人的同名银行账户转账）
      <p>
        备注：外部验证渠道包括但不限于政府部门数据库、商业银行信息系统、商业化数据库等，具体验证方式及流程以我们页面提示为准。
      </p>
      <p>（二）网络支付服务</p>
      <p>
        1、
        网络支付服务是本公司向您提供的非金融机构支付服务，是受您委托代您收款或付款的资金转移服务。其中，代收代付款项服务是指本公司为您提供的代为收取或代为支付款项的服务。
      </p>
      <p>
        通过代收服务，您可以收到他人向您支付的款项，具体是指自您委托本公司将您银行卡内的资金充值到您的正品支付账户或委托本公司代为收取第三方向您支付的款项之时起至根据您的指令将该等款项的全部或部分实际入账到您的银行账户或正品支付账户之时止（含提现）的整个过程。
        通过代付服务，您可以支付款项给您指定的第三方。代付具体是指自款项从您指定账户（非正品支付账户）出账之时起至本公司根据您或有权方给出的指令将上述款项的全部或部分入账到第三方的银行账户或正品支付账户之时止的整个过程；或自您根据本协议委托本公司将您银行卡的资金充值到您或他人的正品支付账户或自您因委托本公司代收相关款项并入账到您的正品支付账户之时起至委托本公司根据您或有权方给出的指令将上述款项的全部或部分入账第三方的银行账户或正品支付账户之时止的整个过程。您知悉并同意，本公司代收代付后，非经法律程序或者非依本协议之约定，该指令是不可撤销的。
      </p>
      <p>2、 代收代付款项服务的功能有如下几类：</p>
      <p>
        （1）充值，在符合法律法规的相关规定及本公司对外公布的服务规则的前提下，您可以将您银行卡内的资金充值到您的正品支付账户。
      </p>
      <p>
        （2）提现，在符合法律法规相关规定及本公司对外公布的服务规则的前提下，您可以将您正品支付账户内余额划转至您名下的可接收款项的中国大陆银行账户。除被冻结、止付或其他限制款项外，本公司将于收到提现指令后的合理时间内，将相应款项汇入该银行账户。此外，我们可能会对提现进行风险审查，因此可能导致提现操作存在延时，您对此已经知悉并同意接受。
      </p>
      <p>
        （3）转账，在符合法律法规相关规定及本公司对外公布的服务规则的前提下，您可以使用正品支付服务，委托本公司将款项转至收款方正品支付账户或银行账户。<b
          >使用转账服务是基于您对收款方的充分了解（包括但不限于收款方的真实身份及准确的正品支付登录名），请您谨慎核对收款方信息以免受到损失。
        </b>
      </p>
      <br />
      <p><b>四、正品支付会员号和账户的注册、使用、冻结和注销 </b></p>
      <p>（一）注册及激活</p>
      <p>
        1、
        您完成正品支付会员号注册并激活后，即可使用相应的正品支付服务；您还可以按照我们的指引进一步申请并在通过本公司验证后获得正品支付账户，以便使用更多服务。具体验证方式（包括但不限于您需要提供验证的身份要素）以本网站相关页面提示为准。
      </p>
      <p>
        2、
        您清楚知悉并同意，您提交申请并不意味着必然能获得通过，本公司有权依据法律法规及自身管理制度及相关规则独立判断、决定是否接受您成为正品支付的用户或会员以及向您提供部分或全部服务功能。
        <b
          >您应在充分了解申请规则及相应法律后果后进行申请，若未能获得通过的，本公司无义务退还您为进行申请而提交的相关资料，请您予以关注。</b
        >
      </p>
      <p>
        3、
        您在会员号或账户中设置的昵称、头像等必须遵守法律法规、公序良俗、社会公德，且不会侵害其他第三方的合法权益，否则本公司可能会取消您的昵称、头像或者阻止其正常显示，但本公司不对此负有实质审查义务，相关法律后果将由您自行承担。
      </p>
      <p>
        4、
        如您已于正品汇平台注册有正品汇账号，您可通过您的正品汇账号快速完成注册并完成两个账户的信息关联。
      </p>
      <p>（二）使用</p>
      <p>
           身份要素是本公司识别您身份并据此执行您指令的唯一依据，请您务必妥善保管。<b
          >使用身份要素进行的任何操作、发出的任何指令均视为您本人做出且不可撤销。因您的原因造成的账户、密码等信息被冒用、盗用或非法使用，由此引起的一切风险、责任、损失、费用等应由您自行承担。您知悉并同意：
        </b>
      </p>
      <p>
        1、为降低服务风险、保障您的资金安全，且基于不同的设备终端以及您的使用习惯，我们可能采取不同的验证措施识别您的身份，验证场景包括但不限于您在登录正品支付账户时验证您的身份、您在使用正品支付服务做相关交易时验证您的身份等。验证措施包括但不限于采用静态密码、一次性密码、指纹、面容及前述多种验证要素的组合方式对您的登录行为、交易行为等进行验证。根据使用场景的不同，我们可能选择不同的验证方式或者在特定验证方式不成功时提供其他备用验证手段（如有），您已经知悉并对此无异议。
      </p>
      <p>
        2、
        您充分理解并完全接受：保护用户信息安全是我们一贯的政策，我们将会使用各种安全技术和程序存储、保护您的身份要素，防止其被未经授权的访问、使用、复制和泄露，包括但不限于随时采取计算机病毒查杀技术、计算机加密技术等措施进行保护。但这并不免除您对自身身份要素信息的审慎保密、妥善保管义务，如因您自身过错导致任何信息泄露的，除非我们对此另有过错，否则应由您自行承担相应法律后果。
      </p>
      <p>
        3、
        为保障您的资金安全，我们建议您把手机及其他设备的密码设置成与正品支付会员号及账户的密码不一致。如您发现有他人冒用或盗用您的会员号、账户或者正品支付登录名及密码，或您的手机或其他有关设备丢失时，<b>请您立即以有效方式通知本公司；您还可以向本公司申请暂停或停止正品支付服务。</b>您理解本公司对您的请求采取行动需要合理时间，如本公司未在合理时间内采取有效措施，导致您损失扩大的，本公司将就扩大的直接经济损失部分承担责任，但本公司对采取行动之前已执行的指令不承担责任。
      </p>
      <p>
        <b
          >4、
          正品支付会员号和账户仅限您本人使用，不可转让、借用、赠与、继承，但正品支付账户内的相关财产权益可被依法继承。
        </b>
      </p>
      <p>
        <b
          >5、
          基于运行和交易安全的需要，本公司可以暂停或者限制正品支付服务部分功能，或增加新的功能。
        </b>
      </p>
      <p>
        6、
        为了维护良好的网络环境，本公司有时需要了解您使用正品支付服务的真实背景及目的，如本公司要求您提供相关信息或资料的，请您配合提供。
      </p>
      <p>
        7、
        您在正品汇平台上发生的所有交易，可通过正品支付服务完成交易安全校验，且由于您已经授权正品汇按照其制定的《正品汇服务条款》等规则进行处理，本公司不再处理正品汇平台上的交易问题；同时，为了解决您的交易纠纷，您不可撤销地授权本公司可按照正品汇的指令将争议款项的全部或部分支付给交易一方或双方。
      </p>
      <p>（三）账户安全及冻结</p>
      <p>
        1、您知悉并同意，您对您获得的正品支付账户应防范并可能负担的风险包括但不限于：
      </p>
      <p>
        （1）您使用的账户、密码等重要信息可能被其他人猜出、偷窥或通过木马病毒、假冒网站、欺诈电话或短信等手段窃取，从而导致您的信息泄露、资金损失、账户被恶意操作等后果；
      </p>
      <p>
        （2）您的手机可能被盗或未经许可被他人使用，若相关登录/支付密码同时被窃取，可能造成资金损失等情况。若您更换手机号时未取消以原手机号开通的服务，则当原手机号被他人使用时，也可能造成您的账户信息泄露等情况；
      </p>
      <p>
        （3）您登录本网站时使用设备的操作系统、杀毒软件、网络防火墙等客户端软件未及时更新或安装补丁程序，可能导致您信息泄露、资金损失、账户被恶意操作等后果。
      </p>
      <p>
        2、故您有义务采取相应风险防范措施，保障您的账户安全，相关措施包括但不限于：
      </p>
      <p>
        （1）妥善保护您在本网站的账户、密码等重要身份要素、身份认证信息及工具，确保上述重要信息和认证工具不经口头或其它方式发生泄露或被盗用；
      </p>
      <p>
        （2）对所使用设备的操作系统、杀毒软件、网络防火墙等客户端软件采取有效的措施（如及时更新系统或安装补丁程序等）防范操作系统软件漏洞风险、电子设备中的病毒等；
      </p>
      <p>
        （3）避免使用容易被其他人猜出或破解的密码（如：采用生日、电话号码等与个人信息相关的信息或具有规律性的数字或字母组合等），避免设置与其他用途相同的密码并定期或不定期更新其密码；
      </p>
      <p>
        （4）经常关注您账户登录情况，如发现任何非您本人进行或者许可的操作或/及交易，应马上与本公司取得联系。
      </p>
      <p>
        您如果遗忘了登录密码或者登录密码被他人修改，您可以按照本网站公布的账号及密码找回规则，通过本公司提供的账号及密码找回系统程序进行找回，或者在第一时间联系本公司的客服人员协助找回，但本公司不保证一定可以找回。
      </p>
      <p>
        3、您充分理解并完全同意：正品支付会员号、账号虽然系您注册获得，但由本公司所有，初始注册人享有且仅享有相应的使用权，不得继承，但账号项下依法可以继承的财产除外。而且，如果存在下列情形之一的，本公司有权冻结、止付您的正品支付账号，使之永久或者暂时不能使用：
      </p>
      <p>
        （1）正品支付账号、登录密码及/或项下的个人信息已经被泄露或者被他人窃取，并已经有人投诉至本公司或者经由司法机关等国家有权机关提出诉请，要求本公司协助找回的；
      </p>
      <p>
        （2）正品支付账号项下的资金系赃款或者违法所得，并已经有人投诉至本公司，要求本公司立即予以冻结，或者有司法机关等国家有权机关依法要求本公司协助调查、冻结的；
      </p>
      <p>
        （3）本公司、本网站的后台系统发生了黑客攻击、系统崩溃等足以严重影响其使用及/或可能对您的信息安全造成严重侵害的事件，本公司必须采取暂停使用的紧急措施方可避免损失或者避免损失进一步扩大的；
      </p>
      <p>（4）发生其他的需要永久或者暂时冻结您账号的情形。</p>
      <p>
        您对冻结、止付操作存在异议的，可联系本公司按照本公司公示的既定规则进行申诉，您应当为申诉提供相应的证明材料，本公司保留做出任何最终决定之权利。
      </p>
      <p>（四）注销</p>
      <p>
        在需要终止使用正品支付服务时，符合以下条件的，您可以申请注销您的会员号或正品支付账户：
      </p>
      <p>
        1、
        您仅能申请注销您本人的会员号或账户，并依照本公司的流程通过自助或者人工的方式申请注销。
      </p>
      <p>
        2、
        您申请注销的会员号或账户处于正常状态，即您的会员号或账户的信息是最新、完整、正确的，且会员号或账户未被采取止付、冻结等限制措施。
      </p>
      <p>
        3、
        为了维护您和其他用户的合法利益，您申请注销的会员号或账户，应当不存在未了结的权利义务或其他因为注销该账户会产生纠纷的情况，不存在任何未完结交易，没有余额，没有关联任何理财类、消费贷类等资产（包括您或者贷款产品、理财产品发行人应付未付的与前述产品相关的款项）或负债。
      </p>
      <p>
        4、 为了防止资源占用，<b
          >若您属于未经实名认证的用户且您连续3个月或您属于经实名认证的用户且您连续12个月，未使用您的正品支付登录名或本公司认可的其他方式登录过您的会员号或账户，本公司会对该会员号或账户进行注销，您将不能再通过该正品支付登录名登录本网站或使用相关会员号或者账户。如该会员号或账户有关联的理财产品、待处理交易或余额，本公司会协助您处理，请您按照本公司提示的方式进行操作。
        </b>
      </p>
      <p>
        5、
        正品支付会员号或账户注销后，您将无法使用本公司的任何服务，双方的权利义务终止（本协议另有约定不得终止的或依其性质不能终止的除外），同时还可能产生如下结果：
      </p>
      <p>
        （1）任何兑换代码或卡券（包括但不限于积分、优惠券等任何与会员号或账户关联的兑换代码、卡券）均将作废；
      </p>
      <p>
        （2）您仍应对您在注销会员号或账户前且使用正品支付服务期间的行为承担相应责任，包括但不限于可能产生的违约责任、损害赔偿责任及履约义务，同时本公司仍可依据法律法规、监管政策的规定保存您注销前的部分信息；
      </p>
      <p>（3）一旦注销成功，账户记录、账户功能等将无法恢复或提供。</p>
      <p>
        6、
        如您的正品支付账户自开立之日起6个月内无交易记录，则向您重新核实身份之前，本公司将有权暂停向您提供正品支付服务或者注销该正品支付账户。
      </p>
      <br />
      <p><b>五、使用正品支付服务的注意事项 </b></p>
      <p>
        为有效保障您使用正品支付服务时的合法权益，本公司还特别提醒您注意以下事项：
      </p>
      <p>（一）身份验证</p>
      <p>
        1、
        为满足《非银行支付机构网络支付业务管理办法》、《支付机构反洗钱和反恐怖融资管理办法》等法律法规的要求以及使用本服务的核心功能，您需提交您的姓名、国籍、性别、职业、住址、手机号、有效身份证件的种类、号码和有效期限、银行卡信息等信息。您授权本公司在法律规定的范围内保存您的姓名、身份证号、手机号、银行卡号、交易订单、交易记录等信息，用于向您持续性地提供服务。为了能将账户资金变动及时通知到您，以及更好保障您的账户安全，如该等资料发生变更，请您及时通知本公司。为了满足国家法律法规关于非银行支付机构的相关规定，您授权本公司向国家机关、金融机构、企事业单位查询、核实您的身份信息、账户信息。<b
          >您应确保正品支付登录名、会员号或账户绑定的电子邮箱或手机号均为您本人持有，如您占用了他人的电子邮箱或手机号的，为避免给电子邮箱或手机号持有人带来不便或不利影响，也为了您的资金安全，本公司有权将该邮箱或手机号从您的会员号或账户中删除并解除关联。
        </b>
      </p>
      <p>
        <b
          >2、
          在出现下列情形时，请您积极配合本公司核对您的有效身份证件或其他必要文件，留存有效身份证件的彩色扫描件，且完成本公司要求的相关身份验证：
        </b>
      </p>
      <p>
        （1）您办理充值、收取或支付单笔金额人民币1万元以上或者外币等值1000美元以上业务的；
      </p>
      <p>
        （2）您名下全部正品支付账户项下30天内充值、收取及支付总金额累计人民币5
        万元以上或外币等值1万美元以上的；
      </p>
      <p>
        （3）您名下全部正品支付账户余额连续10天超过人民币5000元或外币等值1000美元的；
      </p>
      <p>（4）您使用正品支付服务买卖金融产品或服务的；</p>
      <p>
        （5）您一次性购买不记名预付卡超过人民币1万元，或通过不记名预付卡为正品支付账户累计充值超过人民币1万元的；
      </p>
      <p>（6）您要求变更身份信息或您身份信息已过有效期的；</p>
      <p>（7）本公司认为您的交易行为或交易情况出现异常的；</p>
      <p>
        （8）本公司认为您的身份信息或相关资料存在疑点或本公司在向您提供服务的过程中认为您已经提供的身份信息或相关资料存在疑点的；
      </p>
      <p>（9）本公司认为应核对或留存您身份证件或其他必要文件的其他情形的。</p>
      <p>本条款所称“以上”,包括本数。</p>
      <p>
        3、
        为了满足相关监管规定的要求，您同意按照本公司的要求及通知的时间提供/补充完善您的身份信息以完成身份验证，否则您可能无法进行收款、提现、余额支付等操作，且本公司可能对您的账户余额进行止付或冻结，甚至注销您的账户。
      </p>
      <p>
        <b
          >（二）存在如下情形时，本公司会对您名下会员号或/及正品支付账户暂停或终止提供正品支付服务，或对余额进行止付，且有权限制您所使用的产品或服务功能（包括但不限于对这些会员号或/及账户名下的款项和在途交易采取止付、取消交易、调账等限制措施）：</b
        >
      </p>
      <p>（1）您违反了本协议的约定；</p>
      <p>（2）根据法律法规及有权机关出具的法律文书的规定；</p>
      <p>（3）根据有权机关的要求；</p>
      <p>
        （4）您的会员号或账户操作、资金流向等存在异常时，本公司认为您的会员号或账户可能会产生风险的；
      </p>
      <p>
        （5）您在参加市场活动时有批量注册正品支付会员号或账户、交易内容不符或作弊等违反活动规则、违反诚实信用原则的；
      </p>
      <p>（6）他人向您正品支付账户错误汇入资金等导致您可能存在不当获利的；</p>
      <p>（7）您遭到他人投诉，且对方已经提供了一定证据的；</p>
      <p>
        （8）您可能错误地操作他人正品支付会员号或账户，或者将他人会员号或账户进行了身份验证的。
      </p>
      <p>
            除（2）、（3）规定的情形外，如您申请恢复服务、解除上述止付或限制，请您向我们提供相关资料及身份证明等文件，以便本公司进行核实。
      </p>
      <p>（三）正品支付服务规则</p>
      <p>
        1、
        为了您能获得良好的支付体验，您理解并认可，本公司可根据您的支付习惯或本公司推荐的设置向您提供正品支付服务，如根据您的支付习惯进行支付渠道的排序。
      </p>
      <p>
        2、
        您使用本公司支持的无需输入密码即可付款的方式进行支付时，您可在一定额度内直接完成支付，本公司会按照商家确定的金额从您的资产（扣款渠道视情况而定）里扣款给商家。
      </p>
      <p>
        <b
          >3、
          正品支付服务中您可使用的收付款额度，日累计转账限额和笔数，可能因您使用该支付服务时的监管要求、支付场景、银行额度控制、本公司风险控制、身份要素验证等事由而有不同，具体额度请见相关业务页面提示或本公司网站公告。
        </b>
      </p>
      <p>
        4、
        您认可正品支付会员号或账户的使用记录、交易数据等均以本公司系统记录为准。如您对该等数据存有异议的，可及时向本公司提出异议，并提供相关证据供本公司核实。
      </p>
      <p>
        <b
          >5、 如您使用正品支付代扣服务的，下面任一情况下均会导致扣款不成功：
        </b>
      </p>
      <p>（1）您指定的账户余额不足；</p>
      <p>
        （2）您指定的会员号或账户已被采取止付、冻结或其他限制使用权限的措施；
      </p>
      <p>（3）超出监管部门、本公司或银行规定的付款额度。</p>
      <p>
        6、 您在使用正品支付服务过程中，本协议内容、网页上出现的关于操作的提示或本公司发送到您手机、站内信箱的信息内容是您使用正品支付服务的相关规则。
      </p>
      <p>
        7、
        本公司将通过发送短信、电话、站内信等方式向您发送通知，例如通知您交易进展情况，或者提示您进行相关操作，请您及时予以关注。
      </p>
      <p>
        <b
          >8、
          您应自行承担您使用正品支付服务期间的任何货币贬值、汇率波动及收益损失等风险，同时，本公司没有向您支付资金利息或任何其他资金收益的义务。
        </b>
      </p>
      <p>（四）正品支付服务的承诺和声明</p>
      <p>
        1、
        本公司并非银行，本协议项下涉及的资金转移均通过银行来实现，您知悉并同意接受资金在转移途中产生的合理时间，且同意任何因银行端差错导致的支付服务问题（包括但不限于额度限制、支付时效等）都属于本公司不可控制的因素，本公司就此免责。
      </p>
      <p>
        2、
        您理解，基于相关法律法规，对本协议项下的任何服务，本公司均不提供任何担保、垫资。
      </p>
      <p>
        3、
        本公司会将您委托本公司代收或代付的款项，严格按照法律法规或有权机关的监管要求进行管理，请您确保您委托代收付的资金具有合法来源及用途，本公司保留进行核查之权利。
      </p>
      <p>
        <b
          >4、
          本公司提示您注意，正品支付账户所记录的资金余额不同于您本人的银行存款，不受《存款保险条例》保护，其实质为您委托本公司保管的、所有权归属于您的预付价值。该预付价值对应的货币资金虽然属于您，但不以您本人名义存放在银行，而是以本公司名义存放在银行，并且由本公司向银行发起资金调拨指令。
        </b>
      </p>
      <p>（五）交易风险提示</p>
      <p>
        1、
        在使用正品支付服务时，若您或您的交易对方未遵从本协议或相关网站说明、交易、支付页面中之操作提示、规则，则本公司有权拒绝为您与交易对方提供服务，且本公司不承担损害赔偿责任。<b
          >若发生前述情形，而款项已先行入账您的正品支付账户，您同意本公司有权直接自相关账户中扣回相应款项及（或）禁止您要求支付此笔款项之权利。因此导致本公司遭遇其他损失的，本公司保留追偿之权利。</b
        >
      </p>
      <p>
        <b
          >2、差错争议处理流程：当您发现您的正品支付账户或与正品支付账户相关联的您的银行账户出现盗卡盗刷等非本人或非经本人授权的交易行为时，请您立即联系本公司或发卡银行。本公司承诺将及时根据您的反映采取相关措施，如冻结您的正品支付账户、拦截非经您授权划转的在途资金（如有）、通知发卡银行采取必要措施、通知收款商户拦截在途物流（如有），但您完全理解，前述措施是否能采取取决于具体案件情况，采取措施也需要合理时间，本公司不承诺前述措施皆可以采取，也不承诺经过前述措施就能保证您的资金不会因风险交易而遭受损失。本公司将与您相互合作，并积极配合有关第三方（包括但不限于银行、电信部门、公安机关等）查明原因以妥善解决，由此产生的损失应由实际责任方承担。本公司依据法律法规及相关监管规定设立有风险准备金制度和交易赔付规则，并将按照前述规定对不能有效证明因客户原因导致的资金损失及时履行相应的先行赔付义务，但日后如有证据证明不属于本公司赔付责任范围的，本公司保留追偿之权利并有权要求您予以配合，您承诺会完全配合。
        </b>
      </p>
      <p>
        <b
          >3、
          在您使用正品支付服务购买金融类产品时，为了您的利益，建议您仔细阅读金融类产品的协议以及页面提示，确认销售机构及产品信息，谨慎评估风险后再购买。本公司不对您使用本协议项下服务购买金融类产品提供任何保证，也不介入任何因前述产品、业务导致的您与金融类产品发行人、其他投资人等之间的纠纷。</b
        >
      </p>
      <p>
        <b
          >4、鉴于互联网及网络交易的特殊性，本公司无法鉴别和判断相关交易各主体之民事权利和行为能力、资质、信用等状况，也无法鉴别和判断虚拟交易或正在交易或已交易之商品（无论是虚拟还是实物）来源、权属、真伪、性能、规格、质量、数量等权利属性、自然属性及其他各种状况。因此，请您在交易前应自行确认自身及交易对手是适格交易主体，并加以仔细辨明，慎重考虑和评估交易可能产生的各项风险，自主决定是否开始、推进、终止或者撤销交易等，本公司不予干涉也不对交易的合法性、有效性及符合您的需求承担任何保证责任。
        </b>
      </p>
      <p>（六）服务费用</p>
      <p>
        在您使用正品支付服务时，本公司有权向您收取服务费用。本公司拥有制订及调整服务费之权利，<b>具体服务费用以您使用正品支付服务时本公司网站或产品页面上所列之收费方式公告或您与本公司达成的相关书面协议为准。</b>除非另有说明或约定，您同意本公司有权自您委托本公司代收或代付的款项或您任一正品支付账户余额中或者其他资产中直接扣除上述服务费用。
      </p>
      <br />
      <p><b>六、 信息收集、使用、共享 </b></p>
      <p>（一）信息收集</p>
      <p>
        为更好地向您提正品供支付服务，除您在申请或使用正品支付服务时主动向本公司提供的个人信息外，
        <b
          >您同意并授权本公司通过以下场景或途径记录您在使用正品支付服务过程中的
          必要信息：</b
        >
      </p>
      <p>
        1、
        为了您能使用余额支付服务进行消费、转账以及购买投资理财等金融类产品（以支付账户类型支持为准），我们会记录您的支付账户余额信息以及交易信息；
      </p>
      <p>
        2、
        为了您能使用快捷支付服务，实现使用银行卡对正品支付账户余额的充值、提现以及基于银行卡的消费、转账及购买投资理财等金融类产品，您需提供您的开户行名称、银行卡卡号、银行卡有效期、姓名、身份证号码、银行预留手机号等（银行卡信息中的敏感信息，包括但不限于卡片有效期、卡片安全码等，如因业务确需采集，我们将通过强效加密等技术措施进行保护，且我们承诺不会进行存储。另外，例如：银行卡磁道信息或芯片信息、验证码及密码等法定、监管规定不得收集的敏感信息，我们不会收集。具体您可详见我们的《正品支付快捷服务协议》），我们会将前述信息与发卡银行进行验证。在您使用快捷支付服务时，我们会记录您的交易信息；
      </p>
      <p>
        3、
        为了准确执行您的支付指令，在您向他人的正品支付账户转账时，您需按照页面提示输入收款人账户、收款人部分姓名、转账金额；在您转账至他人银行卡时，您需输入收款人姓名、卡号、开户银行。我们会记录您的交易信息；
      </p>
      <p>
        4、
        当您在购物消费时，根据法律法规及监管规定，为防范套现或欺诈风险，我们将直接收集或向商家收集您的交易信息，包括交易金额、交易对象、交易商品、交易时间、配送信息（如有）；
      </p>
      <p>
        5、
        为了保障您的账户和资金安全，在您进行一些重要的账户操作时（例如：找回密码、挂失账户、冻结交易资金），我们需要验证您的身份，为此您可能需向我们提供身份证件影印件、手持身份证照片及其他在具体业务开展过程中基于您的同意而收集的可识别您身份的证明资料，我们会留存前述资料；
      </p>
      <p>
        6、
        为了保障向您提供的服务的安全稳定运行，预防交易和资金风险，我们需要记录您使用正品支付服务时的设备型号、IP地址、设备软件版本信息、设备识别码、设备标识符以及其他与服务相关的日志信息；
      </p>
      <p>
        7、
        其他基于服务，我们需要收集您的信息时，我们会通过页面提示、交互流程、协议约定的方式另行向您说明信息收集的范围与目的，并征得您的同意。我们会按照本协议以及相应的服务协议约定使用、存储、对外提供及保护您的信息。
      </p>
      <p>
        <b
          >我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内，并将按照《支付机构反洗钱和反恐怖融资管理办法》规定的期限保存您的身份资料、交易记录。在您终止使用正品支付服务后，我们会停止对您信息的收集和使用，法律法规或监管部门另有规定的除外。
        </b>
      </p>
      <p>（二）信息使用</p>
      <p>
        为了遵守国家法律法规及监管要求，以及向您提供服务及提升服务质量，或保障您的账户和资金安全，<b
          >我们会在以下情形中使用您的信息：
        </b>
      </p>
      <p>1、 实现上述“信息收集”所述目的；</p>
      <p>2、 为了使您知晓使用正品支付服务的状态，我们会向您发送服务提醒；</p>
      <p>
        3、
        为了保障服务的稳定性与安全性，且履行本公司的法定义务（包括但不限于反洗钱、反恐怖融资、客户身份识别义务等），我们会将您的信息用于身份实名验证、安全防范、诈骗监测、预防或禁止非法活动、降低风险、存档和备份用途；
      </p>
      <p>4、 根据法律法规或监管要求向相关部门进行报告；</p>
      <p>
        5、
        我们会采取脱敏、去标识化等方式对您的信息进行综合统计/分析，以便为您提供更加准确、便捷的服务，或帮助我们评估、改善或设计服务；
      </p>
      <p>6、 经您同意或者应您要求的其他用途。</p>
      <p>（三）信息共享</p>
      <p>
        <b
          >我们承诺对您的信息进行严格保密。除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的信息。</b
        >如果为了向您提供服务而需要将您的信息共享至第三方，我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施，并且严格遵守相关法律法规与监管要求。另外，我们会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认、弹窗提示等形式征得您的同意，或确认第三方已经征得您的同意。
      </p>
      <p>
        1、
        为了让您完成交易、实现交易目的，我们需要向您的交易相对方提供交易号与相关支付信息，同时您的交易相对方可在您付款后查看相关交易信息；
      </p>
      <p>
        2、
        某些产品或服务可能由第三方提供或由我们与第三方共同提供，因此，只有共享您的信息，才能提供您需要的产品或完成你需要的服务；
      </p>
      <p>3、 根据法律法规、监管政策规定及有权机关的要求；</p>
      <p>4、 其他事先获得您明确的同意或授权。</p>
      <p>
        <b
          >在涉及本公司资产转让、收购、兼并、重组或破产清算时，如涉及到个人信息转让，我们会向您告知有关情况，并要求新的持有您个人信息的公司、组织继续受本协议的约束。
        </b>
      </p>
      <br />
      <p>b 七、用户合法使用正品支付服务的承诺</p>
      <p>
        1、
        您应遵守中华人民共和国相关法律法规，不得将正品支付服务用于任何非法目的（包括用于禁止或限制交易物品的交易），也不得以任何违法违规（包括但不限于违反本公司已经书面告知的使用规则）方式使用正品支付服务。
      </p>
      <p>
        <b
          >2、
          您不得利用正品支付服务从事侵害国家、集体、他人合法权益之行为，否则本公司有权进行调查、延迟结算或拒绝提供正品支付服务，且您应承担所有相关法律责任，因此导致本公司或其他方受损的，您应承担赔偿责任。
        </b>
      </p>
      <p>3、 上述1和2适用的情况包括但不限于：</p>
      <p>
        （1）侵害国家、集体利益，侵害他人名誉权、隐私权、商业秘密、商标权、著作权、专利权等合法权益；
      </p>
      <p>（2）违反依法定或约定之保密义务；</p>
      <p>（3）冒用、盗用他人名义使用正品支付服务；</p>
      <p>
        （4）从事不法交易行为，如洗钱、恐怖融资、贩卖枪支、毒品、禁药、盗版软件、黄色淫秽物品、其他本公司认为不得使用正品支付服务进行交易的物品等；
      </p>
      <p>（5）提供赌博资讯或以任何方式引诱他人参与赌博；</p>
      <p>
        （6）未经授权使用他人银行卡、记名的现金等价物（如预付卡），或利用信用卡等金融产品套取现金（以下简称“套现”）；
      </p>
      <p>
        （7）进行与您或交易对方宣称的交易内容、用途不符的交易，或不真实的交易；
      </p>
      <p>（8）从事任何可能侵害正品支付服务系统、数据之行为。</p>
      <p>
        <b
          >4、
          您理解，正品支付服务有赖于系统的准确运行及操作。若出现系统差错、故障或其他原因引发的展示错误、您或本公司不当获利等情形，您同意本公司可以采取更正差错、扣划款项等适当纠正措施。
        </b>
      </p>
      <br />
      <p><b>八、权益保障及隐私保护 </b></p>
      <p><b>1、 客户权益保障承诺</b></p>
      <p>
        本公司一直秉承保护客户合法权益原则，并一直努力为您的权益提供各种保障。
      </p>
      <p><b>2、 用户资金保障 </b></p>
      <p>
        本公司始终重视保护用户的资金安全，确保用户的账户资金、交易资金安全存放，按照您的交易指令准确及时划转，并将自有资金和用户资金分开存放，承诺不挪用、侵占、盗用或者通过其他方式非法占用用户资金。
      </p>
      <p><b>3、 客服沟通及投诉保障 </b></p>
      <p>
        （1）沟通及投诉通道：本公司为您提供便捷有效的客服沟通渠道，保障您在使用正品支付服务过程中的投诉及异议权。我们设立了我们的微信公众号（公众号名“凯华技术”），建立了合法独立域名的网站（www.kaihuau.com）和统一的客户服务电话，您可通过我们的微信公众号、网站了解本公司相关信息，获知正品支付服务相关信息及我们已发布的相关服务规则、客户公告等。如您对本公司或本服务有任何疑问、咨询、投诉等，您可通过前述客服电话或者本公司公布的其他联络方式联系我们进行沟通，我们为此配备专业部门和人员据实、准确、及时处理您的服务需求。
      </p>
      <p>
        <b>（2）客服处理流程与规则：</b
        >如您对本服务有任何电询需求（含交易差错和投诉），您可拨打我们的客服电话，向我们的客服人员说明电询原因、内容等相关信息，我们的客服人员将如实记录您的需求信息。如您的需求在客服人员服务范围内能为您处理的，我们的客服人员将即刻为您妥善解决。如您的需求在客服人员服务范围、权限、能力之外或因其他原因客服人员无法即刻为您妥善解决的，本公司将尽合理商业努力，争取在24小时内为您妥善解决并以您同意的方式向您反馈解决结果。
      </p>
      <p><b>4、 隐私权保护</b></p>
      <p>
        本公司重视对用户隐私的保护，并对您的身份资料和其他特定资料提供保护及保障。具体详见本协议第六部分“信息收集、使用、共享”及本网站上相关的隐私保护政策，本公司及本公司关联方在不侵害您合法权益的前提下，可能会对前述隐私保护政策进行修订并通过在本网站公示、邮件、站内信、短信等方式告知，请您予以留意。
      </p>
      <p>
        如您认为您的隐私权受到侵害或者需要我们对隐私保护政策进行进一步阐明的，您可致电我们的客服电话寻求帮助。
      </p>
      <br />
      <p><b>九、不可抗力、免责、责任划分及赔付规则 </b></p>
      <p>（一）免责条款</p>
      <p>因下列原因导致本公司无法正常提供服务，本公司不承担任何责任：</p>
      <p>（1）本公司系统停机维护、故障修复、调试或升级；</p>
      <p>（2）因台风、地震、洪水、雷电或恐怖袭击等不可抗力原因；</p>
      <p>
        （3）用户的计算机软硬件设备设施和通信线路、供电线路出现故障或者其他不可用情形的；
      </p>
      <p>（4）用户操作不当或通过非本公司授权或认可的方式使用正品支付服务的；</p>
      <p>
        （5）因病毒、木马、恶意程序攻击、网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、银行原因
        、第三方服务瑕疵或政府行为等原因；
      </p>
      <p>（6）其他依据法律法规、监管规定本公司无需承担责任的情形。</p>
      <p>尽管有前款约定，本公司将采取合理行动积极促使服务恢复正常。</p>
      <p>（二）责任划分及赔付规则</p>
      <p>
        除双方协议另有约定外，我们与您之间的责任划分及赔付规则按以下约定兜底执行：
      </p>
      <p>
        1、
        正品支付用户信息是由用户本人自行提供的，本公司无法保证该信息之准确、及时和完整。
      </p>
      <p>
        2、
        本公司可能同时为您及您的（交易）对手方、业务竞争方提供正品支付服务，您同意对本公司可能存在的该等行为予以明确豁免任何实际或潜在的利益冲突，并不得以此来主张本公司在提供正品支付服务时存在法律上的瑕疵。
      </p>
      <p>
        3、 您知悉并同意，本公司在国家相关法律法规规定的范围内，只按现有状况提供标准第三方支付服务，本公司非交易一方，也非交易任何一方之代理人或代表。对于因交易本身而产生的任何纠纷，应由交易双方依据国家有关法律加以解决，除法律法规另有规定或者涉及侵害本公司权益或本公司被判定/认定存在过错外，本公司不参与其中，不承担任何赔付责任。如因前述纠纷导致本公司遭遇任何诉争、行政处罚、经济损失或者重大负面影响的，除非本公司另有过错，否则我们保留追究责任方赔偿责任之权利。
      </p>
      <p>
        4、
        本公司不希望出现任何人利用本公司服务而侵犯他人合法权益的行为。本公司将依据国家法律采取必要的措施防止发生前述各类行为或降低发生这类行为的可能性或者降低由此造成的损失及其后果。对于因前述各类情形而产生的任何纠纷，应由权利受到侵害之人和侵权方依据国家有关法律加以解决，本公司不参与其中，不承担任何赔付责任。如因前述行为导致本公司遭遇任何诉争、行政处罚、经济损失或者重大负面影响的，除非本公司另有过错，否则我们保留追究责任方赔偿责任之权利。
      </p>
      <br />
      <p><b>十、廉洁条款 </b></p>
      <p>
        您承诺，在签署本协议及使用本服务过程中，您将不得以任何形式从事违反或者可能触犯反商业贿赂的行为，本公司亦不对您从事上述行为。双方有权互相监督，任何一方发现对方违反本约定，有权提前解除/终止协议，如您发现本公司任何工作人员向你实施前述行为，你可拨打我们的客服电话进行举报。
      </p>
      <br />
      <p><b>十一、协议的完整性及变更 </b></p>
      <p>
        1、
        本协议各条款是可分的，所约定的任何条款如果部分或者全部无效，不影响该条款其他部分及本协议其他条款的法律效力。
      </p>
      <p>
        2、
        本公司保留单方变更本协议之权利。如本协议发生变更的，本公司将通过公司官网公告的方式提前予以公布，变更后的协议在公告期届满起生效。若您在协议生效后继续使用正品支付服务的，表示您接受变更后的协议，也将遵循变更后的协议使用正品支付服务。
      </p>
      <br />
      <p><b>十二、知识产权的保护 </b></p>
      <p>
        1、
        本公司及关联公司所有系统及本网站上所有内容，包括但不限于著作、图片、档案、资讯、资料、网站架构、网站画面的安排、网页设计，均由本公司或本公司关联公司依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。
      </p>
      <p>
        2、
        非经本公司或本公司关联公司书面同意，任何人不得擅自使用、修改、反向编译、复制、公开传播、改变、散布、发行或公开发表本网站程序或内容。
      </p>
      <p>3、 尊重知识产权是您应尽的义务，如有违反，您应承担损害赔偿责任。</p>
      <br />
      <p><b>十三、法律适用与管辖 </b></p>
      <p>
        本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国（港澳台地区除外）法律。因本协议产生之争议，均应依照中华人民共和国法律予以处理。
      </p>
      <p>本协议的签订地为广东省深圳市福田区并接受协议签订地人民法院之管辖。</p>
      <br />
      <br />
      <br />
      <p>附件</p>
      <h3>广东省银行账户/支付账户涉电信网络新型违法犯罪</h3>
      <h3>法律责任及防范提示告知书</h3>
      <p>
        　　为打击治理电信网络新型违法犯罪，保护人民群众财产安全，根据相关规定，现将开立、使用人民币银行结算账户或支付账户（以下统称“账户”）的法律责任及防范提示告知如下：
      </p>
      <p>
        　　一、个人申请开立账户时，应提供本人真实身份证件，如实填写个人信息；单位申请开立账户时，应按照《人民币银行结算账户管理办法》（中国人民银行令〔2003〕第5号）等规定提交相关证明文件。
      </p>
      <p>
        　　二、任何单位和个人不得出租、出借、出售、购买账户。发生上述行为构成电信网络诈骗犯罪的，依据《最高人民法院
        最高人民检察院
        公安部关于办理电信网络诈骗等刑事案件适用法律若干问题的意见》（法发〔2016〕32号）第四条的规定，对相关责任人以电信网络诈骗共同犯罪论处。
      </p>
      <p>
        　　三、对经设区的市级及以上公安机关认定的出租、出借、出售、购买账户的单位和个人及相关组织者，假冒他人身份或者虚构代理关系开立账户的单位和个人，银行机构、非银行支付机构将依据《中国人民银行关于加强支付结算管理防范电信网络新型违法犯罪有关事项的通知》（银发〔2016〕261号），5年内暂停其银行账户非柜面业务、支付账户所有业务，3年内不得为其新开立账户。人民银行将相关单位和个人信息移送金融信用信息基础数据库并向社会公布。
      </p>
      <p>
        　　四、单位或个人申请开立账户时，如有以下情形应立即停止开户业务，请求银行机构、非银行支付机构工作人员帮助或拨打110报警：
      </p>
      <p>
        　　(一）自称是“公安、检察、法院、海关、医保、社保”等执法人员，要求将资金转移至“安全账户”或验资的；
      </p>
      <p>
        　　（二）自称是“家人、朋友、领导、老师、熟人、恋人”等各类关系人，要求进行存钱、汇款或转账的；
      </p>
      <p>
        　　(三）自称可以办理“退货、退税、退票、发放补贴、中奖兑换、积分兑换、提高信用卡额度、刷信誉返利、低息高额贷款”等各类“获利”行为，要求进行存钱、汇款或转账的；
      </p>
      <p>
        　　(四）使用“举报信、PS照片、黑社会报复”等各类“恐吓”手段，要求进行存钱、汇款或转账的；
      </p>
      <p>　　（五）其他类似情形。</p>
      <p>
        <b
          >　　本开户申请人确认：已知晓上述账户涉电信网络新型违法犯罪的法律责任及防范提示告知内容，将依法申请开立、使用并妥善保管本人（或本单位）账户。
        </b>
      </p>
      <br />
      <p>
        <b
          >　　本人（单位）充分了解并清楚知晓出租、出借、出售、购买账户的相关法律责任和惩戒措施，承诺依法依规开立和使用本人（单位）账户。
        </b>
      </p>
      <br />
      <h3>开户申请人（或单位）：（签章）</h3>
      <p style="text-align: center">  年   月   日</p>
      <p>了解并同意</p>
    </div> -->
    <!-- 租赁与服务 -->
    <!-- <div v-else-if="$route.params.name === 'LeasingAndServices'">
      <h3>智能零售终端（鹏芯多维云机）</h3>
<h3>租赁与服务合同</h3>

甲方（出租方）：深圳天翼鹏芯技术有限公司
甲方地址：深圳市福田区深南大道6029号世纪豪庭大厦9楼
 
乙方（承租方）：
乙方身份证号：
乙方地址：
乙方手机号码： 
   
依据《中华人民共和国民法典》及相关规定，甲乙双方本着真诚合作、平等互利的原则，经双方友好协商达成一致，特签订本合同。 
一、标的及服务
本合同为甲方向乙方出租鹏芯多维云机，亦即由深圳天翼鹏芯技术有限公司和深圳正品汇商务贸易有限公司联合研制推出的、集线上商城、进销存管理、收付款和商品正品验证于一体的智能移动零售终端。
为实现多维云机的进销存管理、收付款、商品验证等功能，甲方联合银行、保险、物流等企业为乙方提供全方位服务。包括：
1、海量正品，保险保证。
鹏芯多维云机上自带的线上商城，提供海量正品优选商品，由平台合作保险公司提供保险加持。（中国人保财险、中国太平保险、平安保险、国任保险、太平洋保险）。
2、消费查询，价廉物美。 
团员（消费者）在收到货品后，扫描货品包装上的二维码，可查询该物品的分布式账册，实现全生产物流链路的可追溯，分布式账册包括生产厂家、电子保单、原产地、检验检测、物流轨迹、赋码防伪等。
3、优厚回报，结算便捷。
店长在鹏芯多维云机上自带的线上商城享有特定的店长价。商城商品明码标价，店长价和团员价保有合理利润空间；通过平台合作银行和保险公司的支持以及平台的运营指导，店长得到多元赋能，获客增收且结算便捷。
二、操作流程
1、浏览及下单
乙方在成为店长并激活鹏芯多维云机后，可在鹏芯多维云机上自带的线上商城浏览商品，并在团购群、朋友圈等渠道对线上商品进行宣传，按照团员（消费者）意愿在线上对库存商品进行下单，并对商品生产厂商进行支付。
2、点验及入库
乙方在收到货物后，使用鹏芯多维云机对商品二维码进行扫码操作，系统将反馈货物确认信息，认证商品真实性后，乙方将完成签收动作，并完成商品入库操作。
3、出库及收款
商品点验入库后，乙方可通知团员（消费者）上门自提所选购的商品，并对商品二维码进行扫描完成商品出库动作；消费者需出示由平台合作银行的付款码进行支付操作。
4、退货及售后
团员（消费者）在收到货品一定期限内，符合平台规定的，可向店长提起退货，货款将由厂家退还给店长，店长再将货款退还给团员（消费者）。
三、甲方权利与义务
1、甲方与乙方签订本协议并负责向乙方提供鹏芯多维云机，甲方租给乙方的鹏芯多维云机软硬件设备的所有权属于甲方。
2、甲方为乙方提供平台注册指导。
3、甲方为乙方提供线上、线下等多种方式的业务技能培训及技术支持，保障乙方正常使用。
4、除明确约定外，不干涉乙方正常的经营活动。
5、在乙方操作鹏芯多维云机失误导致相关数据出现差错时，甲方应提供相应技术指导。
6、甲方有权向乙方收取基于鹏芯多维云机的押金及租金（具体详见本协议第六项）。
四、乙方权利与义务
1、在租赁期内，乙方应按使用要求正确使用和保养鹏芯多维云机；如人为原因造成机器损坏，要承担机器维修的全部费用。
2、乙方不得擅自对鹏芯多维云机进行任何更改内部数据、拆修、改装、更换部件、挪作他用、出租、转让、转借等，否则所产生的一切损失由乙方承担，对鹏芯多维云机造成的损坏由乙方负责赔偿。
3、乙方应按照甲方的要求提供有关本人或本企业的备案资料。
4、乙方须按月向甲方支付鹏芯多维云机租金费用，否则甲方有权暂停或终止乙方对鹏芯多维云机的正常使用。
5、如发现鹏芯多维云机发生故障，应在24小时之内拨打甲方服务电话（服务电话：           ），甲方将及时提供在线处理服务；如确有需要，甲方将委派技术人员及时到达现场提供服务；在现场2小时内不能修复的，甲方提供备用机更换。   
五、租赁数量及型号
设备型号	设备数量	IMEI
		
		
		

六、费用（及支付方式）
1、押金及付款方式
合同签订之日起2个工作日内，乙方应支付其所租赁鹏芯多维云机成本相当的押金 3000 元/台。乙方通过银行实时转账方式付至甲方指定银行账户，甲方在收到押金后5个工作日内向乙方交付鹏芯多维云机并提供押金收款收据。
2、租金及服务费（及付款方式）
鹏芯多维云机租金每月租赁费 100 元/台，鹏芯多维云机交付给乙方激活时，需向甲方支付第一个月租赁费，次月到期前5日鹏芯多维云机将以信息提醒的方式提醒乙方缴费。乙方在线签署租金托收业务，甲方在收到租金5个工作日内提供电子发票。
甲方收款账户明细如下所示：
账户名：
账户号:
开户行：
七、保密条款
1、双方应当对本合同的内容以及在签订、履行本合同过程中知悉或获得的所有有关对方的信息（即“保密信息”）予以保密，包括但不限于一方（披露方）向另一方（接受方）直接或间接披露的有关披露方的相关信息以及与披露方的业务、业务计划、业务战略、销售情况、营运情况、业务关系、财产、营运模式、现有产品或拟开发产品有关的信息，而不论此信息是否由披露方制作。除非该信息是：披露方以书面方式明确注明为非保密性质的信息；或公众已经知晓的或通过公开渠道可获得的信息；或接受方从有权披露该信息的第三方获取的信息，且接受方对该信息无保密义务。
2、未经披露方书面同意，接受方不得将保密信息用于本合同以外的目的，并不得将其泄漏给任何第三方。接受方的雇员为履行本合同约定而必须接触相关保密信息时，接受方可以将此部分保密信息披露给该雇员，但接受方应当告知该雇员相关的保密义务并和其签署与本保密条款在实质上相当的书面保密协议。
3、本条款在本合同终止或解除后继续有效。
八、租赁期限、退租及补偿 
1.乙方自   年  月  日至   年  月  日租赁甲方鹏芯多维云机，如若人为损坏须照价赔偿。
2.除非任何一方在租赁期限届满前三十日书面通知另一方不再续展，否则租赁期限将自动续展，续展的期限与本合同租赁期限相同，续展次数不受限制。除非双方另有约定，否则本协议内容对于续展期仍有约束力。
    3.租赁期内乙方要求提前退租的、以其行为表明将不履行合同或擅自退租的，将扣除押金的10 %作为对甲方的补偿，并由甲方收回鹏芯多维云机。 
4.租约到期且不续租的，甲方收回鹏芯多维云机，凭押金收据退还乙方押金。   
九、违约责任 
1.甲乙双方任一方违约，都必须向守约方赔偿损失。
2.若因不可抗力造成本合同中的条款无法履行，双方均无须承担违约责任。
3.以上违约责任条款不因本合同的解除、终止、变更而失效。
十、纠纷处理
所有因本合同引起的或与本合同有关的任何争议，双方应通过友好协商予以解决。如双方不能通过友好协商解决，则任何一方均有权将争议提交甲方所在地的人民法院诉讼裁判。   
十一、其他 
1.本合同自双方签字或盖章之日起生效。 
2.本合同之未尽事宜双方可另行协商解决，必要时可作出补充规定，补充规定经双方签字盖章后与本合同具有同等法律效力。   
3.本合同一式肆份，甲乙双方各持两份，具有同等法律效力。 



甲方（出租方盖章） ：           乙方（承租方盖章/签名）： 
授权代表：                      授权代表：
日期：                          日期：     
    </div> -->
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  name: "agreement",
  components: {
    pdf,
  },
  data() {
    return {
      currentPage: 1, // pdf文件页码
      pageCount: 15, // pdf文件总页数
      src: "/Agreement/PrivacyPolicy.pdf", // pdf文件地址
    };
  },

  created() {
    this.src = pdf.createLoadingTask(this.src);
    // let vm = this;
    // const xhr = new XMLHttpRequest();
    // xhr.open("get", "/Agreement/t.docx", true); //  获取word路径
    // xhr.responseType = "arraybuffer";
    // xhr.onload = function () {
    //   if (xhr.status == 200) {
    //     console.log(xhr.response);
    //     // mammoth
    //     //   .convertToMarkdown({ arrayBuffer: new Uint8Array(xhr.response) })
    //     //   .then(function (resultObject) {
    //     //     vm.$nextTick(() => {
    //     //       vm.wordHtml=marked(resultObject.value)
    //     //       console.log(resultObject.value)
    //     //     });
    //     //   });
    //   }
    // };
    // xhr.send();

    // console.log(xhr);
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
      //禁止f12
      // document.onkeydown = new Function("event.returnValue=false");
    });
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.t {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#agreement {
  background: #fff;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  cursor: default;
  flex-direction: column;
  .content {
    max-width: 1200px;
    padding: 40px 20px;
    p {
      margin-bottom: 16px;
    }
    b {
      font-weight: 600;
    }
    span {
      font-weight: 600;
      text-decoration: underline;
    }
    a {
      color: rgb(0, 0, 255);
    }
    h3 {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 16px;
    }
  }
}
</style>