<template>
  <div class="announcement">
    <img src="@/assets/images/gg.jpg" width="100%" alt="" />
  </div>
</template>

<script>
export default {
  name: "announcement",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.announcement {
  width: 1200px;
  margin: 0 auto;
  img {
    display: block;
  }
}
</style>
